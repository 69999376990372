import { Avatar, Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import defaultImage from "src/assets/images/profileImage.jpg";
import { userRoles } from "../../../lib/constants";
import PropTypes from "prop-types";
import { alignRight } from "../../../lib/styleConstants";
import theme from "../../../theme";
import MultiLineTextArea from "../elements/TypographyElements/MultiLineTextArea";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import MenuBar from "../MenuBar";
import { copyTextToClipBoard } from "../../../lib/helpers";
import Dialogue from "../Dialogue";
import { deleteCommunicationLogs } from "../../../services/communication";
import { useSnackbar } from "notistack";

const styles = {
  profilePicture: {
    height: "60px",
    width: "60px",
    alignSelf: "center",
    borderRadius: "50%",
  },
  boldText: {
    fontWeight: 500,
    alignSelf: "center",
    marginLeft: "3px",
  },
  readColor: {
    color: theme.palette.primary.main,
  },
  warningColor: {
    color: theme.palette.status.errorDark,
  },
  readBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  rightMove: {
    justifyContent: "flex-end",
    textAlign: "right",
  },
};

const MessageText = ({
  content,
  // senderInfo,
  userRole,
  reload,
  setReload,
  isProject,
}) => {
  const isClient = userRole === userRoles.client;
  const isAdmin = userRole === userRoles.admin;
  const [isDelete, setIsDelete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const copyTextFcn = () => {
    if (!content.deleted) {
      copyTextToClipBoard(content.message);
    } else {
      enqueueSnackbar("You can not copy a deleted message.", {
        variant: "error",
      });
    }
  };

  const deleteTextFcn = () => {
    setIsDelete(true);
  };
  const deleteMessage = async () => {
    if (!content.deleted) {
      const deleteStatus = await deleteCommunicationLogs(
        content.missionId,
        content.id
      );
      if (deleteStatus.status === "Success") {
        enqueueSnackbar(deleteStatus.message, { variant: "success" });
      } else {
        enqueueSnackbar(deleteStatus.message, { variant: "error" });
      }
      setIsDelete(false);
      setReload(!reload);
    } else {
      enqueueSnackbar("This message is already deleted.", {
        variant: "error",
      });
    }
  };

  const menuOptions = [
    {
      label: "Copy",
      action: copyTextFcn,
    },
    isAdmin && {
      label: "Delete",
      action: deleteTextFcn,
    },
  ];

  const date = {
    color: isAdmin
      ? theme.palette.adminBlue.main
      : isClient
      ? theme.palette.black.coolGrey
      : theme.palette.secondary.main,
  };

  return (
    <Grid container spacing={2} mb={4}>
      <Grid item xs={3} md={1}>
        <Avatar
          alt="Remy Sharp"
          src={content?.senderInfo?.profileImageURL || defaultImage}
          sx={styles.profilePicture}
        />
      </Grid>
      <Grid item xs={5} md={8} sx={{ alignSelf: "center" }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle1Med">
              {content?.senderInfo?.name || "n/a"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body3">
              {content.sender === userRoles.admin
                ? "Globhe representative"
                : (content?.senderInfo?.city || "n/a") +
                  " , " +
                  (content?.senderInfo?.location || "n/a")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {!isProject && (
        <Grid item xs={4} md={3}>
          <Grid container sx={styles.rightMove}>
            <Grid xs={12} item>
              {/* <MoreHorizIcon onClick={() => setMessageAction(!messageAction)} /> */}
              <MenuBar
                menuOpener={<MoreHorizIcon />}
                menuOptions={menuOptions}
              />
            </Grid>

            {
              <>
                {content?.sender !== userRoles.admin &&
                  !(
                    content.viewer === userRoles.client ||
                    content.viewer === userRoles.pilot
                  ) &&
                  !(content.viewer === userRole) &&
                  !(userRole === userRoles.admin) && (
                    <Grid
                      item
                      xs={12}
                      md={content.viewer === userRoles.all ? 6 : 12}
                    >
                      <Box sx={styles.readBox}>
                        {content?.seenByAdmin || content.seen ? (
                          <CircleIcon sx={styles.readColor} />
                        ) : (
                          <CircleOutlinedIcon />
                        )}
                        <Typography variant="body3" sx={styles.boldText}>
                          Admin
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                {content?.sender !== userRoles.client &&
                  !(
                    content.viewer === userRoles.admin ||
                    content.viewer === userRoles.pilot
                  ) &&
                  !(content.viewer === userRole) &&
                  !(userRole === userRoles.client) && (
                    <Grid
                      item
                      xs={12}
                      md={content.viewer === userRoles.all ? 6 : 12}
                    >
                      <Box sx={styles.readBox}>
                        {content?.seenByClient || content.seen ? (
                          <CircleIcon sx={styles.readColor} />
                        ) : (
                          <CircleOutlinedIcon />
                        )}

                        <Typography variant="body3" sx={styles.boldText}>
                          Client
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                {content?.sender !== userRoles.pilot &&
                  !(
                    content.viewer === userRoles.admin ||
                    content.viewer === userRoles.client
                  ) &&
                  !(content.viewer === userRole) &&
                  !(userRole === userRoles.pilot) && (
                    <Grid
                      item
                      xs={12}
                      md={content.viewer === userRoles.all ? 6 : 12}
                    >
                      <Box sx={styles.readBox}>
                        {content?.seenByPilot || content.seen ? (
                          <CircleIcon sx={styles.readColor} />
                        ) : (
                          <CircleOutlinedIcon />
                        )}

                        <Typography variant="body3" sx={styles.boldText}>
                          Operator
                        </Typography>
                      </Box>
                    </Grid>
                  )}
              </>
            }
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} sx={{ marginLeft: "5px" }}>
        <Grid container>
          <Grid item xs={12} md={10}>
            {/* <Typography variant="body1">{content.message}</Typography> */}
            {content.deleted ? (
              <Typography variant="h6" style={styles.warningColor}>
                This message is deleted
              </Typography>
            ) : (
              <MultiLineTextArea
                defaultValue={content.message}
                isDisabled
                variant="plain"
                minRows={1}
                displayText
              />
            )}
          </Grid>
          <Grid item xs={12} md={2} sx={alignRight}>
            <Typography variant="body3" sx={date}>
              {content.updatedAt?.slice(0, 10) +
                " , " +
                content.updatedAt?.slice(11, 16)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Dialogue
        showDialogue={isDelete}
        setShowDialogue={setIsDelete}
        showCancelButton={true}
        sumitButtonText={"Delete"}
        onSubmit={() => deleteMessage()}
        submitButtonLook={"primary"}
        bottomDeviderLine={true}
        DialogueBody={
          <Typography variant="h6" sx={{ textAlign: "center" }} mb={3}>
            Are you sure want to delete this message?{" "}
          </Typography>
        }
        maxWidth={"sm"}
        cancelButtonText="Cancel"
      />
    </Grid>
  );
};

MessageText.propTypes = {
  content: PropTypes.object.isRequired,
  // senderInfo: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  reload: PropTypes.bool,
  setReload: PropTypes.func,
  isProject: PropTypes.bool,
};

MessageText.defaultProps = {
  reload: false,
  setReload: undefined,
  isProject: false,
};

export default MessageText;
