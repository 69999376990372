import {
  Box,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Radio,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MapComponent from "./MapComponent";
import {
  areaUnits,
  specifyLocationTooltipInfo,
} from "../../../../lib/constants";
import theme from "../../../../theme";
import TooltipInfo from "../../../global/elements/TooltipInfo";
import PropTypes from "prop-types";
import { orderBox } from "../../../../lib/styleConstants";
import TextStructures from "../../../global/elements/TypographyElements/TextStructures";

const styles = {
  infoIconCls: {
    margin: "auto",
  },
  orderTypeErrorCls: { color: theme.palette.status.errorDark },
  iconCls: {
    color: theme.palette.black.darkSeaBlack,
    fontSize: "17px",
  },
  iconRoundCls: {
    "& svg": {
      width: "30px",
      height: "30px",
      stroke: "white",
      strokeWidth: 1,
    },
  },
  errorMsg: {
    color: theme.palette.status.errorDark,
  },
  radioElement: {
    width: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: "20px",
  },
};

const SpecifyLocationSingleOrder = ({
  orderData,
  setFieldValue,
  setOpen,
  handleOpen,
  mapSaved,
  setMapSaved,
  errorDisplay,
  setErrorDisplay,
  serialNo,
}) => {
  const [area, setArea] = useState(orderData ? orderData?.locationMap : null);
  const [areaSize, setAreaSize] = useState(orderData?.areaSize);
  const [selectedUnit, setSelectedUnit] = useState(areaUnits?.sqkm);
  const [kmlUrl, setKmlUrl] = useState(orderData?.kmlFile);
  const [guidelinesPdfUrl, setGuidelinesPdfUrl] = useState(
    orderData?.guidelinesPdfUrl
  );
  const [polygon, setPolygon] = useState(null);
  const [mapApi, setMapApi] = useState(null);
  const [geocoder, setGeocoder] = useState(null);
  const [label, setLabel] = React.useState(orderData?.locationLabel);
  const [selectedOrderType, setSelectedOrderType] = useState(
    orderData?.orderType
  );
  const [orderTypeError, setOrderTypeError] = useState(null);

  const onMapSave = ({ locationLabel, type, area, areaSize }) => {
    setLabel(locationLabel);
    setFieldValue("locationLabel", locationLabel);
    setFieldValue("orderType", type);
    setFieldValue("locationMap", area);
    setErrorDisplay(false);
    setFieldValue("areaSize", areaSize);
  };
  useEffect(() => {
    if (orderTypeError) {
      setErrorDisplay(true);
    } else {
      setErrorDisplay(false);
    }
  }, [orderTypeError]);

  const handleChange = (event) => {
    setSelectedOrderType(event.target.value);
    setFieldValue("orderType", event.target.value);
  };

  return (
    <Box sx={orderBox}>
      <TextStructures
        text="Specify your location"
        number={serialNo}
        marginBottom
      />
      <FormControl>
        <TextStructures
          text="Choose a location type to draw on the map"
          noUnderline
        />
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="area"
          name="row-radio-buttons-group"
          value={selectedOrderType || ""}
          onChange={handleChange}
        >
          <Box sx={styles.radioElement}>
            <FormControlLabel
              value="area"
              control={<Radio />}
              label="Area order"
              sx={styles.iconRoundCls}
            />
            <TooltipInfo
              info={specifyLocationTooltipInfo.areaInfo}
              type="outlined"
              additionalStyle={styles.iconCls}
            />
          </Box>
          <Box sx={styles.radioElement}>
            <FormControlLabel
              value="linear"
              control={<Radio />}
              label="Linear order"
              sx={styles.iconRoundCls}
            />
            <TooltipInfo
              info={specifyLocationTooltipInfo.linearInfo}
              type="outlined"
              additionalStyle={styles.iconCls}
            />
          </Box>
          <Box sx={styles.radioElement}>
            <FormControlLabel
              value="assets"
              control={<Radio />}
              label="Individual site"
              sx={styles.iconRoundCls}
            />
            <TooltipInfo
              info={specifyLocationTooltipInfo.assetInfo}
              type="outlined"
              additionalStyle={styles.iconCls}
            />
          </Box>
        </RadioGroup>
      </FormControl>
      {((orderTypeError && area && kmlUrl) ||
        (orderTypeError && area && !kmlUrl)) && (
        <Box>
          <Typography variant="subtitle1Med" sx={styles.orderTypeErrorCls}>
            {orderTypeError}
          </Typography>
        </Box>
      )}

      <Box>
        <MapComponent
          area={area}
          setArea={setArea}
          areaSize={areaSize}
          setAreaSize={setAreaSize}
          selectedUnit={selectedUnit}
          setSelectedUnit={setSelectedUnit}
          kmlUrl={kmlUrl}
          setKmlUrl={setKmlUrl}
          guidelinesPdfUrl={guidelinesPdfUrl}
          setGuidelinesPdfUrl={setGuidelinesPdfUrl}
          polygon={polygon}
          setPolygon={setPolygon}
          mapApi={mapApi}
          setMapApi={setMapApi}
          geocoder={geocoder}
          setGeocoder={setGeocoder}
          label={label}
          setFieldValue={setFieldValue}
          onSave={onMapSave}
          mapHeight={{ xs: "608px" }}
          orderData={orderData}
          selectedOrderType={selectedOrderType}
          setOrderTypeError={setOrderTypeError}
          setOpen={setOpen}
          handleOpen={handleOpen}
          mapSaved={mapSaved}
          setMapSaved={setMapSaved}
          setSelectedOrderType={setSelectedOrderType}
          setErrorDisplay={setErrorDisplay}
        />
      </Box>

      {errorDisplay && (
        <Typography variant="body1" sx={styles.errorMsg}>
          Please draw a map or upload a kml file
        </Typography>
      )}
    </Box>
  );
};

SpecifyLocationSingleOrder.propTypes = {
  open: PropTypes.bool.isRequired,
  orderData: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  setOpen: PropTypes.func,
  handleOpen: PropTypes.func,
  mapSaved: PropTypes.bool,
  setMapSaved: PropTypes.func,
  errorDisplay: PropTypes.bool,
  setErrorDisplay: PropTypes.func,
  serialNo: PropTypes.string,
};

SpecifyLocationSingleOrder.defaultProps = {
  setOpen: undefined,
  orderData: undefined,
  handleOpen: undefined,
  mapSaved: false,
  setMapSaved: undefined,
  errorDisplay: false,
  setErrorDisplay: undefined,
  serialNo: "4",
};

export default SpecifyLocationSingleOrder;
