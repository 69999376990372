import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Divider, Grid, Typography, capitalize } from "@mui/material";
import SummaryOrderHeader from "./SummaryOrderHeader";
import OrderFieldDesc from "./OrderFieldDesc";
import theme from "../../../../theme";
import {
  OrderTypeConstant,
  higherPriceMapTypes,
  missionMapTypes,
  orderLocationType,
  orderLocationTypeLabel,
  recursiveInterval,
  returnMsg,
} from "../../../../lib/constants";
import MapTypes from "./MapTypes";
import SingleLocationMap from "./SingleLocationMap";
import MultipleLocationMap from "./MultipleLocationMap";
import {
  flexColumn,
  fontWeightFive,
  greyHeadingColorCls,
} from "../../../../lib/styleConstants";
import ShowAdvancedOption from "./ShowAdvancedOption";
import {
  findZoneFromLocationLabel,
  getDateFromTimeStamp,
  getDateTimeWithFormat,
  convertMissionAreaSizeToHectares,
  expectedTotalCostForMission,
} from "../../../../lib/helpers";
import StandardSpec from "./StandardSpec";

const styles = {
  quotationContainerCls: {
    padding: "55px",
  },
  orderTypeBox: { marginTop: "5%", marginBottom: "5%" },
  dividerCls: {
    borderColor: theme.palette.grey.dividersColor,
    width: "100%",
  },
  orderNameBox: { marginTop: "2%", marginBottom: "5%" },
};

const advancedOptionKeys = [
  "flightHeight",
  "frontOverlap",
  "sideOverlap",
  "spatialResolution",
  "droneType",
  "sensors",
  "crs",
  "gcp",
  "processingSoftware",
  "verticalAccuracy",
];
const Summary = ({ orderPlaced, summary, orderSwitch, orderCollection }) => {
  const [totalCost, setTotalCost] = useState(null);

  const orderTypeLocation =
    orderSwitch == orderLocationType.singleLocationOrder
      ? orderLocationTypeLabel.singleLocationOrder
      : orderLocationTypeLabel.multiLocationOrder;

  const orderName =
    orderSwitch == orderLocationType.singleLocationOrder
      ? summary?.missionName
      : summary?.missionName;
  const country = summary?.location || "";
  const date = summary?.startDate
    ? getDateFromTimeStamp(summary.startDate)
    : "Start once the quote is accepted";
  const deadline = summary.deadline
    ? getDateFromTimeStamp(summary.deadline)
    : "";
  const recurrence = summary?.recurrence || false;
  const orderType = summary?.orderType || OrderTypeConstant.area;
  const mapTypes = missionMapTypes.filter((map) =>
    summary?.mapTypes?.includes(map.label)
  );
  const locationMap = summary?.locationMap || [];
  const locationLabel = summary?.locationLabel || "";
  const description = summary?.description || "";
  const areaSize = summary?.areaSize || 0;
  const advancedOption = getAdvanceOption();
  const advanced = Object.keys(advancedOption).length == 0 ? false : true;
  const uploadValidData = summary?.allCreatedValidMissions || [];

  useEffect(() => {
    if (orderCollection == "missions") {
      const higherValuedMap = mapTypes.find((element) =>
        higherPriceMapTypes.includes(element?.label)
      );

      if (higherValuedMap) {
        setTotalCost(returnMsg);
      } else {
        if (orderType == OrderTypeConstant.area) {
          const zone = findZoneFromLocationLabel(summary?.location);
          const areaHect = convertMissionAreaSizeToHectares({
            orderType: orderType,
            areaSize: areaSize,
          });
          calculateExpectedCost(areaHect, zone);
        } else {
          setTotalCost(returnMsg);
        }
      }
    }
  }, []);

  const calculateExpectedCost = (areaInHectare, zone) => {
    const cost = expectedTotalCostForMission({
      areaInHectare: areaInHectare,
      zone: zone,
    });
    setTotalCost(cost);
  };

  function getAdvanceOption() {
    let obj = {};
    for (let i = 0; i <= advancedOptionKeys.length; i++) {
      if (summary && summary[advancedOptionKeys[i]]) {
        if (advancedOptionKeys[i] == "gcp") {
          obj[advancedOptionKeys[i]] = summary[advancedOptionKeys[i]]
            ? "yes"
            : "No";
        } else {
          obj[advancedOptionKeys[i]] = summary[advancedOptionKeys[i]];
        }
      }
    }
    return obj;
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        {orderPlaced ? (
          <Typography variant="h5" sx={greyHeadingColorCls}>
            Summary
          </Typography>
        ) : (
          <SummaryOrderHeader
            orderTypeLocation={orderTypeLocation}
            cost={totalCost}
            summary={summary}
          />
        )}

        <Box sx={styles.orderTypeBox}>
          <OrderFieldDesc label={"Order Type"} value={orderTypeLocation} />
        </Box>

        <Divider sx={styles.dividerCls} />

        <Box sx={styles.orderNameBox}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OrderFieldDesc label={"Order name"} value={orderName} />
            </Grid>
            <Grid item xs={12}>
              <OrderFieldDesc label={"Country"} value={country} />
            </Grid>

            {recurrence ? (
              <>
                <Grid item xs={12} md={6}>
                  <OrderFieldDesc
                    label={"Repeat every"}
                    value={`${summary?.everyCount}  ${summary?.recursiveInterval}`}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {summary?.recursiveInterval == recursiveInterval.year && (
                    <OrderFieldDesc
                      label={"Repeat on"}
                      value={`${getDateTimeWithFormat(
                        summary?.startDateForMission,
                        "date-month",
                        true
                      )}`}
                    />
                  )}

                  {summary?.recursiveInterval == recursiveInterval.month && (
                    <OrderFieldDesc
                      label={"Repeat on"}
                      value={`${getDateTimeWithFormat(
                        summary?.startDateForMission,
                        "date",
                        true
                      )}`}
                    />
                  )}

                  {summary?.recursiveInterval == recursiveInterval.week && (
                    <OrderFieldDesc
                      label={"Repeat on"}
                      value={capitalize(summary?.startDateForMission)}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {summary?.intervalEndOn && (
                    <OrderFieldDesc
                      label={"Ends on"}
                      value={getDateFromTimeStamp(summary?.intervalEndDate)}
                    />
                  )}

                  {summary?.intervalEndAfter && (
                    <OrderFieldDesc
                      label={"Ends after"}
                      value={`${summary?.intervalEndCount} occurence`}
                    />
                  )}
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <OrderFieldDesc label={"Start date"} value={date} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <OrderFieldDesc label={"Deadline"} value={deadline} />
                </Grid>
              </>
            )}
          </Grid>
        </Box>

        <Divider sx={styles.dividerCls} />

        <Box sx={styles.orderNameBox}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OrderFieldDesc label={"Products"} value={null} />
            </Grid>
            <Grid item xs={12}>
              <MapTypes mapTypes={mapTypes} summaryFlag colMdSpacing={3} />
            </Grid>
          </Grid>
        </Box>

        <Divider sx={styles.dividerCls} />

        <Box sx={styles.orderNameBox}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OrderFieldDesc
                label={"Recurrence"}
                value={recurrence ? "Yes" : "No"}
              />
            </Grid>
          </Grid>
        </Box>

        <Divider sx={styles.dividerCls} />

        <Box sx={styles.orderNameBox}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OrderFieldDesc label={"Locations"} value={null} />
              {orderTypeLocation ==
              orderLocationTypeLabel.singleLocationOrder ? (
                <SingleLocationMap
                  orderType={orderType}
                  locationMap={locationMap}
                  locationLabel={locationLabel}
                  areaSize={areaSize}
                />
              ) : (
                <MultipleLocationMap uploadValidData={uploadValidData} />
              )}
            </Grid>
          </Grid>
        </Box>

        <Divider sx={styles.dividerCls} />

        <Box sx={styles.orderNameBox}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OrderFieldDesc label={"Flight specifications"} value={null} />
            </Grid>
            <Grid item xs={12}>
              <StandardSpec />
            </Grid>
            <Grid item xs={12}>
              <OrderFieldDesc label={"Advanced Options"} value={null} />
            </Grid>
            <Grid item xs={12}>
              {advanced ? (
                <ShowAdvancedOption advancedOption={advancedOption} />
              ) : (
                <Box sx={flexColumn}>
                  <Typography variant="body3" sx={fontWeightFive}>
                    None selected
                  </Typography>
                  <Typography variant="body3">
                    GLOBHE will use the standard specifications above
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>

        <Divider sx={styles.dividerCls} />

        <Box sx={styles.orderNameBox}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OrderFieldDesc
                label={"Detailed description"}
                value={description}
                multiLine
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

Summary.propTypes = {
  orderPlaced: PropTypes.bool,
  summary: PropTypes.object,
  orderSwitch: PropTypes.string.isRequired,
  orderCollection: PropTypes.string.isRequired,
};

Summary.defaultProps = {
  orderPlaced: false,
  summary: {},
};

export default Summary;
