import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { advancedOptionLabel } from "../../../../lib/constants";

const ShowAdvancedOption = ({ advancedOption, variant }) => {
  let keys = Object.keys(advancedOption);

  return (
    <Box>
      <ul>
        {keys.map((key, i) =>
          advancedOption[key] ? (
            <li key={i}>
              <Typography variant={variant}>
                {advancedOptionLabel[key]} : &nbsp;{advancedOption[key]}
              </Typography>
            </li>
          ) : null
        )}
      </ul>
    </Box>
  );
};

ShowAdvancedOption.propTypes = {
  advancedOption: PropTypes.object.isRequired,
  variant: PropTypes.string,
};

ShowAdvancedOption.defaultProps = {
  variant: "body3",
};

export default ShowAdvancedOption;
