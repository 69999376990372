import React from "react";
import Textarea from "@mui/joy/Textarea";
import PropTypes from "prop-types";
import {
  multiLineTextAreaStyle,
  multiLineTextAreaStyleDisplay,
} from "../../../../lib/styleConstants";
import { Field } from "formik";
import styled from "styled-components";
import { Box, Tooltip } from "@mui/material";
import { copyTextToClipBoard } from "../../../../lib/helpers";
import { useSnackbar } from "notistack";

const StyledTextarea = styled(Textarea)(() => ({
  fontSize: "0.875rem",
  fontFamily: "NeueHaasGroteskDisp WO2",
}));

const StyledTextareaToDisplay = styled(Textarea)(() => ({
  color: "#101820",
  // lineHeight: "125%",
  letterSpacing: "-0.02em",
  fontWeight: 400,
}));

const MultiLineTextArea = ({
  isFormik,
  name,
  label,
  onBlur,
  defaultValue,
  placeholder,
  variant,
  isDisabled,
  minRows,
  required,
  setFieldValue,
  copyText,
  displayText,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const copyTextFcn = (text) => {
    if (copyText) {
      copyTextToClipBoard(text);
      enqueueSnackbar("Text copied!", { variant: "success" });
    }
  };
  return isFormik ? (
    <Field name={name} label={label} setFieldValue={setFieldValue}>
      {({ field }) => (
        <StyledTextarea
          {...field}
          sx={multiLineTextAreaStyle}
          minRows={minRows}
          required={required}
          placeholder={placeholder}
          variant={variant}
        />
      )}
    </Field>
  ) : copyText ? (
    <Tooltip title={"Click on the text to copy."} placement="top-start">
      <Box onClick={() => copyTextFcn(defaultValue)}>
        <StyledTextarea
          sx={multiLineTextAreaStyle}
          minRows={minRows}
          defaultValue={defaultValue}
          onBlur={onBlur}
          placeholder={placeholder}
          variant={variant}
          disabled={isDisabled}
          required={required}
        />
      </Box>
    </Tooltip>
  ) : displayText ? (
    <StyledTextareaToDisplay
      sx={multiLineTextAreaStyleDisplay}
      minRows={minRows}
      defaultValue={defaultValue}
      onBlur={onBlur}
      placeholder={placeholder}
      variant={variant}
      disabled={isDisabled}
      required={required}
    />
  ) : (
    <StyledTextarea
      sx={multiLineTextAreaStyle}
      minRows={minRows}
      defaultValue={defaultValue}
      onBlur={onBlur}
      placeholder={placeholder}
      variant={variant}
      disabled={isDisabled}
      required={required}
    />
  );
};
MultiLineTextArea.propTypes = {
  isFormik: PropTypes.bool,
  onBlur: PropTypes.func,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  setFieldValue: PropTypes.func,
  variant: PropTypes.string,
  isDisabled: PropTypes.bool,
  minRows: PropTypes.number,
  required: PropTypes.bool,
  copyText: PropTypes.bool,
  displayText: PropTypes.bool,
};
MultiLineTextArea.defaultProps = {
  isFormik: false,
  onBlur: undefined,
  placeholder: undefined,
  name: undefined,
  label: undefined,
  setFieldValue: undefined,
  variant: "outlined",
  isDisabled: false,
  minRows: 5,
  defaultValue: undefined,
  required: false,
  copyText: false,
  displayText: false,
};
export default MultiLineTextArea;
