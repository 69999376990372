import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Divider, Grid, Typography } from "@mui/material";
import MapTypes from "./MapTypes";
import { missionMapTypes } from "../../../../lib/constants";
import { orderBox } from "../../../../lib/styleConstants";
import MapTypeInfo from "./MapTypeInfo";
import theme from "../../../../theme";
import TextStructures from "../../../global/elements/TypographyElements/TextStructures";

const styles = {
  mapTypesCls: {
    padding: "26px",
  },
  dividerCls: {
    width: "1px",
    height: "100%",
    backgroundColor: theme.palette.grey.dividersColor,
  },
  dividerContainer: {
    display: { xs: "none", md: "flex" },
    height: "100%",
  },
  errorMsg: {
    color: theme.palette.status.errorDark,
  },
};

const ChooseProducts = ({
  mapTypes,
  setFieldValue,
  errors,
  touched,
  hideJpg,
  colMdSpacing,
  colXsSpacing,
  numberCount,
  setMapTypesFilter,
  triggerReload,
  setTriggerReload,
  additionalStyle,
}) => {
  const [clickedMap, setClickedMap] = useState("");
  const [jpgFlag, setJpgFlag] = useState(false);
  let selectedItems = [];

  const addMapTypes = () => {
    selectedItems = [...mapTypes, clickedMap];
    setFieldValue("mapTypes", selectedItems);
    if (setMapTypesFilter) {
      setMapTypesFilter(selectedItems);
      setTriggerReload(!triggerReload);
    }
    setClickedMap(null);
  };

  return (
    <Box sx={{ ...orderBox, ...additionalStyle }}>
      <TextStructures
        text="Choose your data deliverables"
        number={numberCount}
      />
      <Box sx={styles.mapTypesCls}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={clickedMap ? 8 : 12}>
            <MapTypes
              mapTypes={missionMapTypes}
              colMdSpacing={colMdSpacing || 2}
              colXsSpacing={colXsSpacing || 12}
              clickable
              selectedMapTypes={mapTypes}
              onRemove={(data) => {
                setFieldValue("mapTypes", data);
                if (setMapTypesFilter) {
                  setMapTypesFilter(data);
                  setTriggerReload(!triggerReload);
                }
              }}
              onSelect={(data) => setClickedMap(data)}
              setJpgFlag={setJpgFlag}
              hideJpg={hideJpg}
            />
            {errors.mapTypes && touched.mapTypes ? (
              <Typography variant="body3" sx={styles.errorMsg}>
                {errors.mapTypes}
              </Typography>
            ) : null}
          </Grid>

          <Grid item md={0.5}>
            {clickedMap && (
              <Box sx={styles.dividerContainer}>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={styles.dividerCls}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={3.5}>
            {clickedMap && (
              <MapTypeInfo
                label={clickedMap}
                onClick={addMapTypes}
                jpgFlag={jpgFlag}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

ChooseProducts.propTypes = {
  mapTypes: PropTypes.array,
  setFieldValue: PropTypes.func.isRequired,
  touched: PropTypes.object,
  errors: PropTypes.object,
  hideJpg: PropTypes.bool,
  colMdSpacing: PropTypes.number,
  colXsSpacing: PropTypes.number,
  numberCount: PropTypes.number,
  setMapTypesFilter: PropTypes.func,
  triggerReload: PropTypes.bool,
  setTriggerReload: PropTypes.func,
  additionalStyle: PropTypes.object,
};

ChooseProducts.defaultProps = {
  mapTypes: [],
  touched: {},
  errors: {},
  hideJpg: false,
  colMdSpacing: 2,
  colXsSpacing: 12,
  numberCount: null,
  setMapTypesFilter: undefined,
  triggerReload: false,
  setTriggerReload: undefined,
  additionalStyle: {},
};

export default ChooseProducts;
