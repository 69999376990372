import React from "react";
import { Box, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import ProjectStatusAndMap from "../../Components/ProjectStatusAndMap";
import UploadedCompletedWeekReport from "../../Components/UploadedCompletedWeekReport";
import ProjectRequirements from "../../Components/ProjectRequirements";
import ProjectTableDetails from "../../Components/ProjectTableDetails";
import {
  projectDroneOperatorColumn,
  projectMissionColumn,
  userRoles,
  projectMissionColumnForClients,
  projectStatus,
  missionStatus,
  projectMissionColumnForPilots,
} from "../../../../../lib/constants";
import PropTypes from "prop-types";
import AppButton from "../../../../global/elements/AppButton";
import { completeProject } from "../../../../../services/project";
import { createProjectInvoice } from "../../../../../services/invoices";
import { useStateIfMounted } from "use-state-if-mounted";
import Dialogue from "../../../../global/Dialogue";
import theme from "../../../../../theme";
import { projectEmail } from "../../../../../services/clientEmail";
import { createNotification } from "../../../../../services/notification";
import firebase from "src/firebase";
import ConfirmDraftProject from "../../Components/ConfirmDraftProject";
import DeleteProject from "../../Components/DeleteProject";

const styles = {
  completedBtn: {
    float: "right",
    margin: "40px 0",
    display: "flex",
    flexDirection: "column",
  },
  paddingBottom: {
    paddingBottom: "50px",
  },
  paddingTop: {
    paddingTop: "7px",
  },
  dialogBody: { marginBottom: "30px", textAlign: "center" },
  dialogueContent: { color: theme.palette.grey.dark },
  draftProjectContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: { xs: "column", sm: "row" },
    rowGap: { xs: "15px", sm: "unset" },
    margin: "40px 0",
  },
};

const ProjectDetailsTab = ({
  // role,
  currentUser,
  projectDetails,
  projectMissions,
  allPilotsData,
  // allAdminsData,
  barchartData,
  triggerReload,
  setTriggerReload,
  changeRole,
}) => {
  const [loading, setLoading] = useStateIfMounted(false);
  const [showDialogue, setShowDialogue] = useStateIfMounted(false);

  const { enqueueSnackbar } = useSnackbar();
  const reloadProject = () => {
    setTriggerReload(!triggerReload);
  };

  const onProjectComplete = async () => {
    try {
      setLoading(true);
      const resp = await completeProject(projectDetails.id);
      if (resp.status == "Success") {
        await createProjectInvoice({ project: projectDetails });
        await projectEmail({
          project: projectDetails,
          functionName: "sendEmailOnProjectComplete",
          errorMessage: "Error in sending project complete emails",
        });
        if (projectDetails.clientId) {
          await createNotification(
            firebase,
            projectDetails?.enterPriseId || projectDetails.clientId,
            "Your project is Completed and ready to download.",
            "Your project is Completed and ready to download.",
            `/app/client/projects/${projectDetails.id}`
          );
        }
      }
      setLoading(false);
      enqueueSnackbar("Project completed successfully", {
        variant: "success",
      });
      setTriggerReload(!triggerReload);
    } catch (e) {
      console.log("Error", e);
      setLoading(false);
      enqueueSnackbar("Error in completing project", {
        variant: "error",
      });
      setTriggerReload(!triggerReload);
    }
  };

  const enableMarkAllRead = () => {
    let allValidMissionCount = projectMissions?.filter(
      (data) =>
        data.status !== missionStatus.deleted ||
        data.status !== missionStatus.archive ||
        data.status !== missionStatus.rejected ||
        data.status !== missionStatus.cancelled
    );

    let allAssignedMissionCount = projectMissions?.filter(
      (data) => data.assignedPilot
    );

    let completedCnt = projectMissions?.filter(
      (data) => data.status === missionStatus.completed
    );

    if (completedCnt?.length == allValidMissionCount?.length) {
      return false;
    }

    if (allValidMissionCount?.length == allAssignedMissionCount?.length) {
      return true;
    } else {
      false;
    }
  };
  const enableComplete = () => {
    let completed = projectMissions?.filter(
      (data) => data.status === missionStatus.completed
    );
    let totalCnt = projectMissions?.filter(
      (data) =>
        data.status !== missionStatus.deleted ||
        data.status !== missionStatus.archive ||
        data.status !== missionStatus.rejected ||
        data.status !== missionStatus.cancelled
    );
    if (completed?.length === totalCnt?.length) {
      return false;
    }
    return true;
  };
  const CompleteProjectDialogueBody = () => {
    return (
      <Box sx={styles.dialogBody}>
        <Typography variant="h6">Complete Project</Typography>

        <Typography variant="body1">
          Are you sure you want to complete the project ?
        </Typography>
      </Box>
    );
  };

  return (
    <Box sx={styles.paddingBottom}>
      <ProjectStatusAndMap
        projectDetails={projectDetails}
        role={changeRole ? userRoles.client : currentUser?.role}
        missionData={projectMissions}
      />
      <UploadedCompletedWeekReport
        barchartData={barchartData}
        projectId={projectDetails?.id}
        currentUser={currentUser}
      />
      <ProjectRequirements
        projectData={projectDetails}
        missionData={projectMissions}
        currentUser={currentUser}
        changeRole={changeRole}
        reloadProject={reloadProject}
      />
      {currentUser?.role === userRoles.admin && !changeRole && (
        <ProjectTableDetails
          title={"DRONE OPERATORS"}
          columns={projectDroneOperatorColumn}
          allData={allPilotsData}
          noDataAvailableText={
            "There are no operators assigned to this project currently."
          }
          // redirectURL={currentUser?.role === userRoles.admin && "/app/pilots"}
        />
      )}
      <ProjectTableDetails
        title={
          currentUser?.role === userRoles.pilot
            ? "MY ASSIGNED SITES"
            : "MISSIONS"
        }
        columns={
          currentUser?.role === userRoles.pilot
            ? projectMissionColumnForPilots
            : currentUser?.role === userRoles.admin && !changeRole
            ? projectMissionColumn
            : projectMissionColumnForClients
        }
        allData={projectMissions}
        noDataAvailableText={"There are no missions in this project currently."}
        projectId={projectDetails?.id}
        triggerReload={triggerReload}
        setTriggerReload={setTriggerReload}
        enableMarkAllRead={enableMarkAllRead()}
        // redirectURL={
        //   currentUser?.role === userRoles.admin ||
        //   currentUser?.role === userRoles.pilot
        //     ? "/app/missions"
        //     : "/app/client/orderDetails"
        // }
      />
      {
        // projectDetails?.status == projectStatus.active &&
        currentUser?.role === userRoles.admin &&
          !changeRole &&
          projectDetails?.status !== projectStatus.draft &&
          projectDetails?.status !== projectStatus.completed && (
            <Box sx={styles.completedBtn}>
              <AppButton
                look="black"
                isDisabled={enableComplete()}
                label="Complete project"
                onClick={() => setShowDialogue(true)}
                submittingState={loading}
              />
              {
                // !projectDetails?.clientInvoiceUrl ? (
                //   <Typography variant="body3" sx={styles.paddingTop}>
                //     *Please upload client invoice.
                //   </Typography>
                // ) : (
                projectDetails?.status !== projectStatus.active && (
                  <Typography variant="body3" sx={styles.paddingTop}>
                    *Project must be in active state to mark as completed.
                  </Typography>
                )
                // )
              }
              {showDialogue && (
                <Dialogue
                  showDialogue={showDialogue}
                  setShowDialogue={setShowDialogue}
                  DialogueHeadText={""}
                  DialogueBody={CompleteProjectDialogueBody()}
                  showCancelButton={true}
                  cancelButtonText={"CLOSE"}
                  sumitButtonText={"COMPLETE PROJECT"}
                  showSubmitLoader={loading}
                  setShowSubmitLoader={setLoading}
                  onSubmit={() => onProjectComplete()}
                  submitButtonLook={"primary"}
                />
              )}
            </Box>
          )
      }
      {projectDetails?.status == projectStatus.draft && (
        <Box sx={styles.draftProjectContainer}>
          <Box>
            <DeleteProject
              project={projectDetails}
              setTriggerReload={setTriggerReload}
              triggerReload={triggerReload}
            />
          </Box>
          <Box>
            <ConfirmDraftProject
              project={projectDetails}
              setTriggerReload={setTriggerReload}
              triggerReload={triggerReload}
              currentUser={currentUser}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

ProjectDetailsTab.propTypes = {
  currentUser: PropTypes.object.isRequired,
  projectDetails: PropTypes.object,
  projectMissions: PropTypes.array,
  allPilotsData: PropTypes.array,
  barchartData: PropTypes.object,
  triggerReload: PropTypes.bool.isRequired,
  setTriggerReload: PropTypes.func.isRequired,
  changeRole: PropTypes.bool,
};

ProjectDetailsTab.defaultProps = {
  projectDetails: {},
  projectMissions: [],
  allPilotsData: [],
  barchartData: {},
  changeRole: false,
};

export default ProjectDetailsTab;
