import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useState, useRef } from "react";
import {
  missionMapTypes,
  subscriptionType,
  submissionRole,
  adminMissionType,
  userRoles,
  missionTypeConstant,
  missionStatus,
} from "src/lib/constants";
import * as Yup from "yup";
import { fieldValidation } from "../../lib/form-helpers";
import MapDrawingTool from "../app/mapDrawingTool/MapDrawingTool";
import KMLDownloadField from "../app/missions/shared/KMLDownloadField";
import KMLFileDropZone from "../app/missions/shared/KMLFileDropZone";
import InfoIcon from "@mui/icons-material/Info";
import theme from "../../theme";
import CheckBox from "../global/elements/CheckBox";
import { CheckboxWithLabel, TextField } from "formik-mui";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormSubmitButton from "../global/elements/FormSubmitButton";
import AcceptTerms from "../global/elements/AcceptTerms";
import {
  Box,
  Modal,
  Typography,
  Tooltip,
  Button,
  Fade,
  IconButton,
  GlobalStyles,
  Autocomplete,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { getUser } from "../../services/auth";
import { areaUnits, OrderTypeConstant } from "../../lib/constants";
// import TooltipInfo from "../../global/elements/TooltipInfo";

const droneMapRequestSchema = {
  clientName: Yup.string().required("Name is required"),
  clientEmail: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  deadline: Yup.date()
    .nullable()
    .required("Deadline is required")
    .test("checkDateInPast", "Deadline date cannot be in the past", (d) => {
      return !!(d && d > new Date());
    }),
  companyName: Yup.string().required("Organisation Name is required"),
  description: Yup.string().required("Purpose Of Data is required"),
};

const subscriptionSchema = {
  subscriptionEnd: Yup.date()
    .nullable()
    .required(
      "Subscription deadline is required when you choose a subscription"
    ),
  subscriptionType: Yup.string(),
};

const missionTypeSchema = {
  missionType: Yup.string().required("Mission Type is required").nullable(),
};

const testMissionChecks = {
  missionName: Yup.string()
    .max(20, "Must be maximum 20 characters long")
    .required("Mission name is required"),
  description: Yup.string().required("Purpose Of Data is required"),
};

const styles = {
  drawingModal: {
    width: "100vw",
    height: "100vh",
    backgroundColor: theme.palette.grey.white,
  },
  mapPreview: {
    width: "100%",
    margin: theme.spacing(1, 0),
    height: "300px",
  },
  mapButton: {
    "&.MuiButton-root": {
      margin: "auto 0 auto auto",
      padding: "8px 0.5vw",
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      marginBottom: "20px",
      fontFamily: "NHaasGroteskDSPro",
    },
  },
  areaInfo: {
    display: "grid",
    gridAutoFlow: "column",
    rowGap: theme.spacing(1),
    columnGap: theme.spacing(1),
    alignItems: "center",
    justifyContent: "left",
  },
  areaInfoHeader: {
    margin: 0,
  },
  purpose: {
    font: "inherit",
    width: "100%",
    height: "100px",
    padding: theme.spacing(2),
    borderColor: "rgb(188, 201, 197)",
    "&::placeholder": {
      color: "rgb(188, 201, 197)",
    },
  },
  customTooltip: {
    backgroundColor: theme.palette.grey.white,
    maxWidth: "380px",
    padding: "10px",
    border: "1px solid rgb(188, 201, 197)",
    textAlign: "justify",
    display: { xs: "none", sm: "block" },
  },
  customTooltip1: {
    backgroundColor: theme.palette.grey.white,
    maxWidth: "250px",
    maxHeight: "250px",
    padding: "10px",
    marginLeft: "160px",
    marginTop: "0px",
    cursor: "pointer",
    border: "1px solid rgb(188, 201, 197)",
    textAlign: "justify",
    display: { xs: "none", sm: "block" },
  },
  title: {
    color: theme.palette.black.darkSeaBlack,
    fontSize: "0.8rem !important",
    marginBottom: "10px !important",
    marginTop: "10px !important",
  },
  content: {
    color: theme.palette.black.coolGrey,
    fontSize: "0.8rem",
    margin: "8px",
  },
  heading: {
    "&.MuiTypography-h5": {
      fontWeight: "500",
      margin: "5px 0 15px 0",
    },
  },
  info: {
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  checkboxGroup: {
    display: "flex",
    flexDirection: "column",
    "& div": {
      float: "left",
      width: "100%",
      marginTop: "4px",
      marginBottom: "4px",
    },
  },
  inputField: {
    marginBottom: "10px",
    boxShadow: "0px 0px 4px #f6f6f7",
    "& .MuiInputBase-root  ": {
      fontSize: "0.8rem !important",
    },
  },

  descInputField: {
    marginBottom: "10px",
    boxShadow: `0px 0px 4px ${theme.palette.primary.main}`,
    borderRadius: "10px",
    minHeight: "5rem",
    border: "none",
    width: { xs: "65vw", sm: "40vw" },
    "& fieldset": {
      border: "none",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  additionalMargin: {
    marginBottom: "20px",
  },
  button_container: {
    marginBottom: "20px",
    marginTop: "20px",
  },
  form: {
    WebkitAppearance: "none",
    MozAppearance: "none",
    msAppearance: "none",
  },
  submit_button: {
    width: "100%",
    float: "right",
    marginBottom: "20px",
  },
  acept_term: {
    width: "100%",
  },
  errorSpan: {
    color: theme.palette.status.errorDark,
    margin: 0,
    fontSize: "0.75rem",
    marginTop: "3px",
    textAlign: "left",
    fontFamily: "NHaasGroteskDSPro",
    fontWeight: 400,
    lineHeight: 1.66,
  },
};

const DroneMapRequestForm = ({
  submitMissionCall,
  submissionType,
  missionType,
  projectMission = false,
  projectData,
}) => {
  const [drawingToolOpen, setDrawingToolOpen] = useState(false);
  const [area, setArea] = useState(null);
  const [areaSize, setAreaSize] = useState(null);
  const [locationLabel, setLocationLabel] = useState(null);
  const [kmlUrl, setKmlUrl] = useState(null);
  const [subscribeData, setSubscribeData] = useState(false);
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [orderType, setOrderType] = useState(false);
  const currentUser = getUser("user")?.role;
  const autoC = useRef(null);

  const validationSchema = Yup.object().shape({
    ...(submissionType == submissionRole.clientSubmission &&
      droneMapRequestSchema),
    ...(submissionType == submissionRole.adminSubmission && missionTypeSchema),
    ...(subscribeData && subscriptionSchema),
    ...(userRoles.admin && testMissionChecks),
  });

  const formInitialValues = {
    _2dMap: "",
    _3dMap: "",
    elevationMap: "",
    aiMap: "",
    missionName: "",
    thermalMap: "",
    originalPhotos: "",
    panoramas: "",
    otherMapType: "",
    companyName: "",
    clientName: "",
    deadline: null,
    daily: "",
    weekly: "",
    monthly: "",
    quarterly: "",
    clientEmail: "",
    description: "",
    subscribe: false,
    subscriptionType: "",
    subscriptionEnd: null,
    missionType: missionType,
  };

  const onMapSave = (_area, _size, _locationLabel, _type) => {
    setArea(_area);
    setAreaSize(_size);
    setLocationLabel(_locationLabel);
    setKmlUrl(null);
    setDrawingToolOpen(false);
    setOrderType(_type);
  };

  const setKML = (url, locData) => {
    setArea(null);
    setAreaSize(null);
    setLocationLabel(null);
    setKmlUrl(url);
    setMapData(locData);
  };

  const setMapData = ({ locMap, areaSize, locationLabel, type }) => {
    setArea(locMap);
    setAreaSize(areaSize);
    setLocationLabel(locationLabel);
    setOrderType(type);
  };

  const clearMap = () => {
    setArea(null);
    setAreaSize(null);
    setLocationLabel(null);
    setDrawingToolOpen(false);
  };

  const drawingToolModal = (
    <Box sx={styles.drawingModal}>
      <MapDrawingTool
        area={area}
        onCancel={() => setDrawingToolOpen(false)}
        onSave={onMapSave}
        type={orderType}
        mapHeight={{
          height: "260px",
          borderRadius: "15px",
          overflow: "hidden",
        }}
      />
    </Box>
  );
  const handleSubscriptionState = () => setSubscribeData((value) => !value);

  const projectMissionSubmission = (values, setSubmitting) => {
    if (projectData?.clientId) {
      values.clientId = projectData?.clientId;
      values.missionType = missionTypeConstant.projectMission;
      values.status = missionStatus.initialProjectOrder;
      values.clientName = projectData?.clientName;
      values.clientEmail = projectData?.clientEmail;
      values.companyName = projectData?.clientCompanyName;
      values.clientPhone = projectData?.clientPhone;
      values.deadline = projectData?.deadline;
      values.projectSearchName = projectData?.projectSearchName;
    } else {
      values.missionType = missionTypeConstant.testMission;
      values.status = missionStatus.initialProjectOrder;
      values.clientName = projectData?.clientName;
      values.clientEmail = projectData?.clientEmail;
      values.companyName = projectData?.clientCompanyName;
      values.clientPhone = projectData?.clientPhone;
      values.deadline = projectData?.deadline;
      values.projectSearchName = projectData?.projectSearchName;
    }

    submitMissionCall(values, setSubmitting)
      .then(() => {})
      .catch((e) => {
        console.log("Error", e);
      });
  };

  const minDate = new Date(new Date().getTime() + 86400000);

  const areaSizeUnitNotation = () => {
    if (orderType == OrderTypeConstant.area) {
      return ` ${areaUnits.sqkm}`;
    } else if (orderType == OrderTypeConstant.linear) {
      return " km";
    } else if (orderType == OrderTypeConstant.assets) {
      return "";
    } else {
      return ` ${areaUnits.sqkm}`;
    }
  };

  return (
    <>
      <GlobalStyles
        styles={{
          body: {
            ".pac-container": {
              zIndex: "100000 !important",
            },
          },
        }}
      />
      <Modal
        open={drawingToolOpen}
        onClose={() => setDrawingToolOpen(false)}
        aria-labelledby="drawing tool"
        aria-describedby="draw desired area on a map"
      >
        {drawingToolModal}
      </Modal>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, setFieldError, resetForm }) => {
            if (area) {
              values.locationMap = area;
            }
            if (areaSize) {
              values.areaSize = areaSize;
            }
            if (locationLabel) {
              values.locationLabel = locationLabel;
            }
            if (kmlUrl) {
              values.kmlFile = kmlUrl;
            }

            if (orderType) {
              values.orderType = orderType;
            }

            if (!area && !areaSize && !kmlUrl) {
              setSubmitting(false);
              setErrorDisplay(true);
              return;
            }

            setErrorDisplay(false);

            values.description =
              values.description && values.description !== ""
                ? "From client: " + values.description
                : "";

            return new Promise(() => {
              if (projectMission) {
                // call project mission
                projectMissionSubmission(values, setSubmitting, resetForm);
              } else {
                submitMissionCall(values, setSubmitting, setFieldError);
                clearMap();
                setKmlUrl(null);
                resetForm();
                if (submissionType == submissionRole.adminSubmission) {
                  const ele = autoC.current.getElementsByClassName(
                    "MuiAutocomplete-clearIndicator"
                  )[0];
                  if (ele) ele.click();
                }
              }
            });
          }}
        >
          {({ isSubmitting, errors, touched, values, setFieldValue }) => {
            const routeChange = () => {
              window.open("https://www.globhe.com/crowddroning-products");
            };
            return (
              <Form>
                <Box sx={styles.form}>
                  {submissionType == submissionRole.clientSubmission ? (
                    <Typography variant="h5" sx={styles.heading}>
                      What area do you need data from?
                    </Typography>
                  ) : (
                    <>
                      <Box sx={{ marginBottom: "10px" }}>
                        <Typography variant="h5" sx={styles.heading}>
                          Mission Name*
                        </Typography>
                        <Field
                          type="text"
                          name="missionName"
                          placeholder="Mission Name"
                          onChange={(e) => {
                            setFieldValue("missionName", e.target.value);
                          }}
                          component={TextField}
                          variant="outlined"
                          sx={{
                            ...styles.inputField,
                            ...styles.additionalMargin,
                            width: "100%",
                          }}
                        />
                      </Box>
                      <Typography variant="h5" sx={styles.heading}>
                        Where area are you providing data from?
                      </Typography>
                    </>
                  )}

                  <Typography variant="body1" sx={styles.info}>
                    Upload a .kml file (max 1MB filesize), or define the area by
                    drawing it on a map.
                  </Typography>
                  {/* <br /> */}
                  {submissionType == submissionRole.clientSubmission && (
                    <Typography sx={styles.info}>
                      Or use our API for faster operations.{" "}
                      <a
                        href="https://developers.globhe.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Check here for info.
                      </a>{" "}
                    </Typography>
                  )}
                  <br />
                  {area && !drawingToolOpen && (
                    <Box sx={styles.mapPreview}>
                      <MapDrawingTool
                        area={area}
                        readonly
                        mapHeight="300px"
                        type={orderType}
                      />
                    </Box>
                  )}
                  {locationLabel && (
                    <Tooltip
                      title="geographical center of marked area"
                      placement="left"
                    >
                      <Box sx={{ ...styles.areaInfo }}>
                        <Typography sx={styles.areaInfoHeader}>
                          Location:
                        </Typography>
                        <Typography>{locationLabel}</Typography>
                      </Box>
                    </Tooltip>
                  )}
                  {areaSize ? (
                    <Box sx={styles.areaInfo}>
                      <Typography sx={styles.areaInfoHeader}>
                        Area size:
                      </Typography>
                      <Typography>{`${areaSize.toFixed(
                        3
                      )} ${areaSizeUnitNotation()}`}</Typography>
                    </Box>
                  ) : (
                    <></>
                  )}
                  <Box
                    sx={{
                      ...styles.button_container,
                      marginTop: areaSize && "25px",
                    }}
                  >
                    <Button
                      disableElevation
                      fullWidth
                      onClick={() => setDrawingToolOpen(true)}
                      variant="outlined"
                      color="primary"
                      sx={styles.mapButton}
                      className={fieldValidation(
                        errors.drawOnMap,
                        touched.drawOnMap
                      )}
                    >
                      {area ? "Edit Area" : "Draw on Map"}
                    </Button>
                    <KMLFileDropZone
                      onUploaded={(url, locData) => {
                        setKML(url, locData);
                        // setMapData(locData);
                        // clearMap();
                      }}
                      currentFileUrl={kmlUrl}
                      className={fieldValidation(
                        errors.uploadKml,
                        touched.uploadKml
                      )}
                    />
                  </Box>

                  {errorDisplay && (
                    <Box component="span" sx={styles.errorSpan}>
                      Please draw a map or upload kml file
                    </Box>
                  )}

                  {kmlUrl && <KMLDownloadField url={kmlUrl} />}
                  {submissionType == submissionRole.clientSubmission ? (
                    <Tooltip
                      componentsProps={{
                        tooltip: { sx: styles.customTooltip1 },
                      }}
                      interactive={"true"}
                      title={
                        <Typography sx={styles.content} onClick={routeChange}>
                          Check examples of what each data represents, their
                          typical usage according to industry, and the type of
                          data formats that are delivered.{""}
                        </Typography>
                      }
                      placement="bottom-end"
                      TransitionComponent={Fade}
                    >
                      <Typography variant="h5">
                        What kind of data do you need?
                        <span>
                          <IconButton size="small" onClick={routeChange}>
                            <InfoIcon color="primary" fontSize="inherit" />
                          </IconButton>
                        </span>
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography variant="h5" sx={styles.heading}>
                      What kind of data are you providing?
                      <IconButton size="small" onClick={routeChange}>
                        <InfoIcon color="primary" fontSize="inherit" />
                      </IconButton>
                    </Typography>
                  )}
                  <Box sx={styles.checkboxGroup}>
                    {missionMapTypes.map(({ value, label }, index) => {
                      return (
                        <Tooltip
                          componentsProps={{
                            tooltip: { sx: styles.customTooltip1 },
                          }}
                          interactive={"true"}
                          title={
                            <Typography
                              sx={styles.content}
                              onClick={routeChange}
                            >
                              Check examples here{" "}
                            </Typography>
                          }
                          placement="bottom"
                          TransitionComponent={Fade}
                          key={value}
                        >
                          <div key={index}>
                            <CheckBox
                              type="checkbox"
                              value={label}
                              name="mapTypes"
                              label={label}
                            />
                          </div>
                        </Tooltip>
                      );
                    })}
                  </Box>
                  <Field
                    type="text"
                    fullWidth
                    name="otherMapType"
                    component={TextField}
                    placeholder="Other Data Type"
                    InputProps={{ disableUnderline: true }}
                    sx={styles.inputField}
                    className={fieldValidation(
                      errors.otherMapType,
                      touched.otherMapType,
                      values.otherMapType
                    )}
                  />

                  <Typography
                    variant="body1"
                    sx={{ ...styles.additionalMargin, ...styles.info }}
                  >
                    * Check this{" "}
                    <a
                      href="https://www.globhe.com/crowddroning-products"
                      target="_blank"
                      rel="noreferrer"
                    >
                      page
                    </a>{" "}
                    for more info about data types and available formats
                  </Typography>
                  {submissionType == submissionRole.clientSubmission ? (
                    <>
                      <Typography variant="h5" sx={styles.heading}>
                        Use Case and Technical Requirements*
                      </Typography>
                      <Tooltip
                        componentsProps={{
                          tooltip: { sx: styles.customTooltip },
                        }}
                        title={
                          <Box>
                            <Typography sx={styles.content}>
                              To help us collect and deliver the best data for
                              you, please provide as many details as possible in
                              regards to your data request.
                            </Typography>
                            <Typography variant="h4" sx={styles.title}>
                              Location
                            </Typography>
                            <Typography sx={styles.content}>
                              Please be as precise as possible when providing a
                              KMZ or using the draw function.
                            </Typography>
                            <Typography variant="h4" sx={styles.title}>
                              Purpose and Use Case
                            </Typography>
                            <Typography sx={styles.content}>
                              Tell us about how the data will be used and
                              benefit your work, this way we can coordinate the
                              best quality product for your project. Include any
                              technical requirements for the data capture or
                              specific requests on file format for the final
                              deliverable.
                            </Typography>
                            <Typography sx={styles.content}>
                              If you are not sure what you need, contact us
                              directly and we can discuss all relevant options.
                            </Typography>
                          </Box>
                        }
                        placement="right"
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                      >
                        <Field
                          type="text"
                          fullWidth
                          multiline
                          InputProps={{ disableUnderline: true }}
                          name="description"
                          component={TextField}
                          placeholder="What will the data be used for? Describe as much as possible your technical requirements"
                          sx={{
                            ...styles.inputField,
                            ...styles.additionalMargin,
                          }}
                          error={Boolean(
                            touched.description && errors.description
                          )}
                          helperText={touched.description && errors.description}
                        />
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Typography variant="h5" sx={styles.heading}>
                        Describe this data and what it represents*
                      </Typography>

                      <Field
                        fullWidth
                        type="text"
                        multiline
                        as="textarea"
                        name="description"
                        component={TextField}
                        placeholder="Write your data description here"
                        InputProps={{ disableUnderline: true }}
                        onChange={(e) => {
                          setFieldValue("description", e.target.value);
                        }}
                        sx={{
                          ...styles.inputField,
                          ...styles.additionalMargin,
                        }}
                      />
                    </>
                  )}

                  {submissionType == submissionRole.clientSubmission && (
                    <>
                      <Typography variant="h5" sx={styles.heading}>
                        Organization/Company Name*
                      </Typography>

                      <Field
                        type="text"
                        fullWidth
                        name="companyName"
                        component={TextField}
                        placeholder="Name of Organisation"
                        InputProps={{ disableUnderline: true }}
                        sx={{
                          ...styles.inputField,
                          ...styles.additionalMargin,
                        }}
                        error={Boolean(
                          touched.companyName && errors.companyName
                        )}
                        helperText={touched.companyName && errors.companyName}
                      />

                      <Typography variant="h5" sx={styles.heading}>
                        Drone Requirements
                      </Typography>
                      <Field
                        type="text"
                        fullWidth
                        name="droneRequirements"
                        component={TextField}
                        InputProps={{ disableUnderline: true }}
                        placeholder="In case you require a particular drone type, write the name of the drone here"
                        sx={{
                          ...styles.inputField,
                          ...styles.additionalMargin,
                        }}
                        className={fieldValidation(
                          errors.droneRequirements,
                          touched.droneRequirements,
                          values.droneRequirements
                        )}
                      />
                      <Typography variant="h5" sx={styles.heading}>
                        Your Name*
                      </Typography>
                      <Field
                        type="text"
                        fullWidth
                        name="clientName"
                        component={TextField}
                        InputProps={{ disableUnderline: true }}
                        placeholder="First & Last name"
                        error={Boolean(touched.clientName && errors.clientName)}
                        helperText={touched.clientName && errors.clientName}
                        sx={{
                          ...styles.inputField,
                          ...styles.additionalMargin,
                        }}
                      />

                      <Typography variant="h5" sx={styles.heading}>
                        When do you need it?*
                      </Typography>
                      <DatePicker
                        inputFormat="dd-MM-yyyy"
                        minDate={minDate}
                        disablePast
                        fullWidth
                        onChange={(value) =>
                          setFieldValue("deadline", value, true)
                        }
                        value={values.deadline}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            label="Deadline"
                            fullWidth
                            sx={{
                              ...styles.inputField,
                              ...styles.additionalMargin,
                            }}
                            error={Boolean(touched.deadline && errors.deadline)}
                            helperText={touched.deadline && errors.deadline}
                          />
                        )}
                      />
                      <Box sx={styles.additionalMargin}>
                        <Typography variant="h5" sx={styles.heading}>
                          Do you want to subscribe and order data from the same
                          location at different times?
                        </Typography>
                        <Box
                          sx={{
                            "& .Mui-checked ": {
                              color: theme.palette.status.successDark,
                            },
                          }}
                        >
                          <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name="subscribe"
                            onClick={handleSubscriptionState}
                            Label={{ label: "Subscribe" }}
                          />
                        </Box>
                      </Box>
                      {subscribeData && (
                        <>
                          <Typography variant="h5" sx={styles.heading}>
                            Choose yout subscription time interval
                          </Typography>
                          <Box
                            sx={{
                              ...styles.additionalMargin,
                              ...styles.checkboxGroup,
                            }}
                          >
                            {subscriptionType.map(({ label, value }, index) => {
                              return (
                                <div key={index}>
                                  <CheckBox
                                    type="radio"
                                    value={value}
                                    name="subscription"
                                    label={label}
                                  />
                                </div>
                              );
                            })}
                          </Box>
                          <Typography variant="h5" sx={styles.heading}>
                            Till when do you want your subscription to last
                            (choose the last mission date)?
                          </Typography>
                          <DatePicker
                            inputFormat="dd-MM-yyyy"
                            disablePast
                            fullWidth
                            onChange={(value) =>
                              setFieldValue("subscriptionEnd", value, true)
                            }
                            value={values.subscriptionEnd}
                            renderInput={(params) => (
                              <MuiTextField
                                {...params}
                                label="Subscription End"
                                fullWidth
                                sx={{
                                  ...styles.inputField,
                                  ...styles.additionalMargin,
                                }}
                                error={Boolean(
                                  touched.subscriptionEnd &&
                                    errors.subscriptionEnd
                                )}
                                helperText={
                                  touched.subscriptionEnd &&
                                  errors.subscriptionEnd
                                }
                              />
                            )}
                          />
                        </>
                      )}
                      <Typography variant="h5" sx={styles.heading}>
                        What’s your email? *
                      </Typography>
                      <Field
                        id="clientEmail"
                        type="email"
                        name="clientEmail"
                        fullWidth
                        component={TextField}
                        placeholder="Email*"
                        InputProps={{ disableUnderline: true }}
                        sx={styles.inputField}
                        error={Boolean(
                          touched.clientEmail && errors.clientEmail
                        )}
                        helperText={touched.clientEmail && errors.clientEmail}
                      />
                    </>
                  )}

                  {submissionType == submissionRole.adminSubmission && (
                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Autocomplete
                        onChange={(e, value) =>
                          setFieldValue("missionType", value)
                        }
                        ref={autoC}
                        value={values.missionType}
                        options={Object.keys(adminMissionType).map(
                          (key) => adminMissionType[key]
                        )}
                        renderInput={(params) => (
                          <Field
                            {...params}
                            type="text"
                            name="missionType"
                            component={TextField}
                            label="Mission type"
                            variant="outlined"
                            style={{ ...styles.inputField }}
                            sx={{ "& label": { fontSize: "0.8rem" } }}
                            error={Boolean(errors.missionType)}
                            helperText={errors.missionType}
                          />
                        )}
                      />
                    </Box>
                  )}
                  <br />
                  <Box sx={styles.submit_button}>
                    <Box
                      style={{
                        width: "100%",
                        float: "right",
                        textTransform: "none",
                      }}
                    >
                      {currentUser == userRoles.pilot ? (
                        <FormSubmitButton
                          label="Submit"
                          submittingState={isSubmitting}
                        />
                      ) : (
                        <FormSubmitButton
                          label="Submit and task a local drone"
                          submittingState={isSubmitting}
                        />
                      )}
                    </Box>
                  </Box>

                  <AcceptTerms />
                </Box>
              </Form>
            );
          }}
        </Formik>
      </LocalizationProvider>
    </>
  );
};

DroneMapRequestForm.propTypes = {
  submitMissionCall: PropTypes.func.isRequired,
  submissionType: PropTypes.string,
  missionType: PropTypes.string,
  projectMission: PropTypes.bool,
  projectData: PropTypes.object,
};
DroneMapRequestForm.defaultProps = {
  submissionType: submissionRole.clientSubmission,
  missionType: missionTypeConstant.clientOrder,
  projectMission: false,
  projectData: {},
};

export default DroneMapRequestForm;
