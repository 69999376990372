import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import TextFieldInput from "../../../global/FormInputComponents/TextFieldInput";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField as MuiTextField } from "@mui/material";
import PropTypes from "prop-types";
import AutoCompleteInput from "../../../global/FormInputComponents/AutoCompleteInput";
import countries from "src/lib/countries";
import {
  autocompletStyle,
  orderBox,
  formInputField,
} from "../../../../lib/styleConstants";
import {
  dataLibCategory,
  missionTypesCreatedByAdmin,
  orderLocationType,
  sdgGoals,
  userRoles,
  formTypeConstant,
  missionTypeConstant,
} from "../../../../lib/constants";
import theme from "../../../../theme";
import firebase from "src/firebase";
import { getClientProfilePercentForCreateOrder } from "../../../../services/client";
import SwitchSelect from "../../../global/elements/SwitchSelect";
import RepeatOrderDetails from "./RepeatOrderDetails";
import TextStructures from "../../../global/elements/TypographyElements/TextStructures";
import AssignClient from "./AssignClient";

const styles = {
  clientError: {
    color: theme.palette.status.errorLight,
  },
  switchValue: { display: "flex" },
};

const OrderDetails = ({
  setFieldValue,
  values,
  currentUser,
  formType,
  touched,
  errors,
  orderCollection,
  orderSwitch,
  setOrderCollection,
  setClientInfo,
  serialNo,
}) => {
  const minDate = new Date(new Date().getTime() + 86400000);
  useEffect(() => {
    if (!firebase) return;
    if (currentUser?.isAnonymous) return;

    const getClientDetails = (userId) => {
      try {
        getClientProfilePercentForCreateOrder({ userId })
          .then((data) => {
            if (data) {
              setClientInfo(data);
              setFieldValue("clientId", userId);
              setFieldValue("clientName", data.name);
              setFieldValue("clientEmail", data.email);
              setFieldValue("clientPhone", data.phone || "");
              setFieldValue("clientCompanyName", data.companyName || "");
              setFieldValue("enterPriseId", data.enterPriseId || null);
            }
          })
          .catch((e) => {
            console.log("Error", e);
          });
      } catch (e) {
        console.log("Error", e);
      }
    };
    if (currentUser?.role == userRoles.client) {
      getClientDetails(currentUser.userID);
    }
  }, [
    orderSwitch,
    orderCollection,
    values.missionType,
    currentUser?.isAnonymous,
    currentUser?.role,
    currentUser?.userID,
    values?.clientCompanyName,
    values?.clientEmail,
    values.clientId,
    values?.clientName,
    values?.clientPhone,
    setClientInfo,
    setFieldValue,
  ]);

  useEffect(() => {
    if (formType == formTypeConstant.create) {
      setFieldValue("recurrence", false);
      setFieldValue("everyCount", 1);
      setFieldValue("recursiveInterval", null);
      setFieldValue("startDateForMission", null);
      setFieldValue("intervalEndDate", null);
      setFieldValue("intervalEndCount", 0);
      setFieldValue("intervalEndOn", false);
      setFieldValue("intervalEndAfter", false);
      setFieldValue("locationLabel", null);
      setFieldValue("orderType", null);
      setFieldValue("locationMap", null);
      setFieldValue("areaSize", null);
      setFieldValue("kmlUrl", null);
    }
  }, [orderSwitch, setFieldValue]);

  return (
    <Box sx={orderBox}>
      <TextStructures text="Orders details" number={serialNo} />
      <Grid container rowSpacing={2} columnSpacing={3} mt={1}>
        <Grid item xs={12} md={6}>
          <TextFieldInput
            setFieldValue={setFieldValue}
            name={"missionName"}
            values={values}
            label="Order name"
            type="text"
            required={true}
            placeholder="Order Name (max 20 characters)"
            disabled={formType === formTypeConstant.edit ? true : false}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AutoCompleteInput
            label="Select country"
            options={countries}
            name="location"
            placeholder={"Country"}
            additionalStyle={autocompletStyle}
            required
            //check this
            value={values.location}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePicker
            inputFormat="dd-MM-yyyy"
            fullWidth
            minDate={minDate}
            disablePast
            onChange={(value) => {
              setFieldValue("startDate", value);
            }}
            value={values.startDate}
            renderInput={(params) => (
              <MuiTextField
                {...params}
                fullWidth
                variant="outlined"
                name="startDate"
                label="Starting date"
                error={Boolean(touched.startDate && errors.startDate)}
                helperText={
                  touched.startDate && errors.startDate
                    ? touched.startDate && errors.startDate
                    : "If you have a specific start date in mind for the data capture to begin, please enter it above. Otherwise, we will start data capture as soon as we can."
                }
                sx={formInputField}
              />
            )}
          />
        </Grid>
        {!values.recurrence && (
          <Grid item xs={12} md={6}>
            <DatePicker
              inputFormat="dd-MM-yyyy"
              fullWidth
              minDate={minDate}
              disablePast
              onChange={(value) => {
                setFieldValue("deadline", value);
              }}
              value={values.deadline}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  fullWidth
                  required
                  variant="outlined"
                  name="deadline"
                  label="Deadline date"
                  error={Boolean(touched.deadline && errors.deadline)}
                  helperText={touched.deadline && errors.deadline}
                  sx={formInputField}
                />
              )}
            />
          </Grid>
        )}

        {currentUser?.role == userRoles.admin &&
          formType == formTypeConstant.edit && (
            <Grid item xs={12} md={6}>
              <TextFieldInput
                setFieldValue={setFieldValue}
                values={values}
                label="Single mission Price"
                name={"missionPrice"}
                type="number"
                required
              />
            </Grid>
          )}
        <Grid item xs={12} mt={2}>
          <Typography variant="h6">Point of contact at the site</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldInput
            setFieldValue={setFieldValue}
            name={"pocName"}
            values={values}
            label="Name"
            type="text"
            placeholder=""
            //disabled={formType === formTypeConstant.edit ? true : false}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldInput
            setFieldValue={setFieldValue}
            name={"pocPhone"}
            values={values}
            label="Phone number"
            type="text"
            placeholder="Phone number"
            //disabled={formType === formTypeConstant.edit ? true : false}
          />
        </Grid>
        <Grid item xs={12}>
          {currentUser.role !== userRoles.pilot &&
            // currentUser.role !== userRoles.admin &&
            ((formType == formTypeConstant.create &&
              orderSwitch !== orderLocationType.multiLocationOrder) ||
              formType == formTypeConstant.edit) && (
              <RepeatOrderDetails
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                formType={formType}
                orderSwitch={orderSwitch}
                touched={touched}
                currentUser={currentUser}
                setOrderCollection={setOrderCollection}
                orderCollection={orderCollection}
              />
            )}
        </Grid>

        {currentUser?.role === userRoles.admin && (
          <>
            <Grid item xs={12} mt={2}>
              <TextStructures text="Data library fields" marginBottom />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography>Show this mission in the data library</Typography>
              <Box sx={styles.switchValue}>
                <SwitchSelect
                  checked={!values.notShowInDataLibrary}
                  handleChange={(e) => {
                    setFieldValue("notShowInDataLibrary", !e.target.checked);
                  }}
                  name="notShowInDataLibrary"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography>Make this mission available for free</Typography>
              <Box sx={styles.switchValue}>
                <SwitchSelect
                  checked={values.freeToDownload}
                  handleChange={(e) => {
                    setFieldValue("freeToDownload", e.target.checked);
                  }}
                  name="freeToDownload"
                />
              </Box>
            </Grid>
            {(orderSwitch == orderLocationType.multiLocationOrder ||
              values.recurrence) && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography>Allow Empty Data</Typography>
                <Box sx={styles.switchValue}>
                  <SwitchSelect
                    checked={values.allowEmptyData}
                    handleChange={(e) => {
                      setFieldValue("allowEmptyData", e.target.checked);
                    }}
                    name="allowEmptyData"
                  />
                </Box>
              </Grid>
            )}
            {!values.notShowInDataLibrary === true && (
              <>
                <Grid item xs={12} md={6}>
                  <TextFieldInput
                    setFieldValue={setFieldValue}
                    values={values}
                    label={"Mission Title"}
                    name={"dataLibMissionTitle"}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AutoCompleteInput
                    options={sdgGoals}
                    name="dataLibSdgGoals"
                    label="SDG Goals"
                    additionalStyle={autocompletStyle}
                    multiple={true}
                    value={values.dataLibSdgGoals}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutoCompleteInput
                    options={dataLibCategory}
                    name="dataLibCategories"
                    label="Data Library Categories"
                    additionalStyle={autocompletStyle}
                    multiple={true}
                    value={values.dataLibCategory}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldInput
                    setFieldValue={setFieldValue}
                    values={values}
                    label={"Data Library Description"}
                    name={"dataLibDescription"}
                    type="text"
                    rows={4}
                    multiline={true}
                  />
                </Grid>
              </>
            )}
            {formType === formTypeConstant.create && (
              <>
                <Grid item xs={12} mt={2}>
                  <TextStructures text="Other basic details" />
                </Grid>
                {orderSwitch === orderLocationType.singleLocationOrder &&
                  !values.recurrence && (
                    <Grid item xs={12} md={6}>
                      <AutoCompleteInput
                        options={missionTypesCreatedByAdmin}
                        name="missionType"
                        label="Mission Type"
                        disabled={
                          formType === formTypeConstant.edit ? true : false
                        }
                        additionalStyle={autocompletStyle}
                      />
                    </Grid>
                  )}

                {(orderSwitch === orderLocationType.multiLocationOrder ||
                  values?.missionType === missionTypeConstant.clientOrder ||
                  orderCollection === "projects") && (
                  <AssignClient
                    formType={formType}
                    setFieldValue={setFieldValue}
                    currentUser={currentUser}
                    values={values}
                    orderCollection={orderCollection}
                    setClientInfo={setClientInfo}
                  />
                )}
                {formType != formTypeConstant.edit && (
                  <Grid item xs={12} md={6}>
                    <TextFieldInput
                      setFieldValue={setFieldValue}
                      values={values}
                      label="Single mission Price"
                      name={"missionPrice"}
                      type="number"
                      required
                    />
                  </Grid>
                )}
              </>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
};

OrderDetails.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object,
  formType: PropTypes.string.isRequired,
  touched: PropTypes.object,
  errors: PropTypes.object,
  currentUser: PropTypes.object,
  orderCollection: PropTypes.string.isRequired,
  orderSwitch: PropTypes.string.isRequired,
  setOrderCollection: PropTypes.func.isRequired,
  setClientInfo: PropTypes.func,
  serialNo: PropTypes.string,
};

OrderDetails.defaultProps = {
  touched: {},
  values: {},
  errors: {},
  currentUser: {},
  setClientInfo: {},
  serialNo: "2",
};

export default OrderDetails;
