import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Form, Formik } from "formik";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTime } from "luxon";
import { preventEnterSubmit } from "src/lib/form-helpers";
import theme from "../../../../../theme";
import { navigate } from "gatsby";
import * as Yup from "yup";
import {
  projectStatus,
  userRoles,
  defaultMissionRepresentative,
  guestUserSignInRoute,
  formTypeConstant,
  orderLocationType,
  orderCollectionData,
  paymentStatus,
} from "../../../../../lib/constants";
import AppButton from "../../../../global/elements/AppButton";
import { getClientProfilePercent } from "../../../../../services/client";
import firebase from "src/firebase";
import { useSnackbar } from "notistack";
import Tips from "../../../../global/elements/Tips";
import { projectEdit } from "../../../../../services/project";
import PropTypes from "prop-types";
import { getUser } from "../../../../../services/auth";
import Dialogue from "../../../../global/Dialogue";
import GuestUserDialogueBody from "../../../AnonymousUser";
import CompleteProfilePopupBody from "../../../../global/CompleteProfilePopupBody";
import GuestStatusAlert from "../../../AnonymousUser/GuestStatusAlert";
import AdvanceOptions from "../../../CreateOrder/Components/AdvanceOptions";
import { getAdvancedOption } from "../../../../../lib/helpers";
import ClientDetail from "../../../CreateOrder/Components/ClientDetail";
import OrderDetails from "../../../CreateOrder/Components/OrderDetails";
import ChooseProducts from "../../../CreateOrder/Components/ChooseProducts";
import ObjAndDesc from "../../../CreateOrder/Components/ObjAndDesc";
import ObjAndDescOperator from "../../../CreateOrder/Components/ObjAndDescOperator";
import SingleLocationMap from "../../../CreateOrder/Components/SingleLocationMap";
import { orderBox } from "../../../../../lib/styleConstants";
import FormErrors from "../../../../global/forms/FormErrors";

const styles = {
  mTop: {
    marginTop: "35px",
  },
  asterisk: {
    color: theme.palette.status.errorDark,
    fontSize: "15px",
  },
  requirementCls: { marginTop: "15px" },
  autocompletStyle: {
    "& .MuiTextField-root": {
      margin: "auto !important",
    },
    "& .MuiFormControl-root": {
      margin: "auto !important",
    },
    "& .MuiOutlinedInput-root": {
      padding: "8.5px",
    },
  },
  projectDetailGrid: {
    marginTop: "10px",
  },
  mainBoxTop: { marginTop: "25px" },
  fieldTitleBox: {
    marginTop: "20px",
  },
  buttonConainer: {
    display: "flex",
    justifyContent: { xs: "center", sm: "space-between" },
    marginTop: "40px",
    flexDirection: "row-reverse",
  },
  twoBtn: {
    display: "flex",
  },
  createBtn: { order: { xs: 1, sm: 3 } },
  clientError: {
    fontSize: "12px",
    color: theme.palette.status.errorLight,
  },
  marginT: { marginTop: { xs: "20px", md: "0px" } },
  errorSpan: {
    color: theme.palette.status.errorDark,
  },
  mapCls: { width: { xs: "100%", md: "50%" } },
  formErrorsAlert: {
    marginTop: "20px",
    marginBottom: "20px",
  },
};

const ProjectCreateEditTab = ({
  projectData,
  currentUser,
  onArchive,
  archived,
}) => {
  const [userData, setUserData] = useState(null);
  const [showDialogue, setShowDialogue] = useState(false);
  const [isGuestUser, setIsGuestUser] = useState(false);

  //variables related to map

  const [clientInfo, setClientInfo] = useState({});

  const { enqueueSnackbar } = useSnackbar();
  const user = getUser("user");

  useEffect(() => {
    if (!firebase) return;
    setUserData(user);

    if (user?.role == userRoles.client && !user?.isAnonymous) {
      getClientDetails(user.userID);
    }
    // }
  }, []);

  const initialValues = {
    projectName: projectData?.projectName || "",
    missionName: projectData?.projectName || "", // This is only added, want to use the orderDetails reuseable component
    projectSearchName: projectData?.projectSearchName,
    mapTypes: projectData?.mapTypes || [],
    country: projectData?.country || "",
    location: projectData?.country || "", // This is only added, want to use the orderDetails reuseable component
    clientCompanyName: projectData?.clientCompanyName || "",
    clientName: projectData?.clientName || "",
    deadline: projectData?.deadline
      ? DateTime.fromISO(projectData?.deadline)
      : null,
    startDate: projectData?.startDate
      ? DateTime.fromISO(projectData?.startDate)
      : null,
    clientEmail: projectData?.clientEmail || "",
    clientPhone: projectData?.clientPhone || "",
    description: projectData?.description || "",
    recurrence: projectData?.recurrence || false,
    everyCount:
      projectData?.recurrenceInfo && projectData?.recurrenceInfo?.everyCount
        ? projectData?.recurrenceInfo?.everyCount
        : 0,
    recursiveInterval:
      projectData?.recurrenceInfo &&
      projectData?.recurrenceInfo?.recursiveInterval
        ? projectData?.recurrenceInfo?.recursiveInterval
        : "",
    intervalEndAfter:
      (projectData?.recurrenceInfo &&
        projectData?.recurrenceInfo?.intervalEndAfter) ||
      false,
    intervalEndOn:
      (projectData?.recurrenceInfo &&
        projectData?.recurrenceInfo?.intervalEndOn) ||
      false,
    intervalEndDate:
      (projectData?.recurrenceInfo &&
        projectData?.recurrenceInfo?.intervalEndDate) ||
      null,
    intervalEndCount:
      (projectData?.recurrenceInfo &&
        projectData?.recurrenceInfo?.intervalEndCount) ||
      "",
    startDateForMission:
      (projectData?.recurrenceInfo &&
        projectData?.recurrenceInfo?.startDateForMission) ||
      "",
    clientId: projectData?.clientId,
    clientLabel:
      (projectData?.clientCompanyName
        ? projectData?.clientCompanyName + "-" + projectData?.clientEmail
        : projectData?.clientEmail) || "",
    missionPrice: Number(projectData?.missionPrice) || 0,
    allowEmptyData: projectData?.allowEmptyData || false,

    missionOwnerName:
      projectData?.missionOwnerName || defaultMissionRepresentative.name,

    missionOwner:
      projectData?.missionOwner || defaultMissionRepresentative?.email,
    missionOwnerId:
      projectData?.missionOwnerId || defaultMissionRepresentative?.id,

    dataLibMissionTitle:
      projectData?.dataLibrary && projectData?.dataLibrary?.missionTitle
        ? projectData?.dataLibrary?.missionTitle
        : "",
    dataLibDescription:
      projectData?.dataLibrary && projectData?.dataLibrary?.description
        ? projectData?.dataLibrary?.description
        : "",
    dataLibSdgGoals:
      projectData?.dataLibrary && projectData?.dataLibrary?.sdgGoals
        ? projectData?.dataLibrary?.sdgGoals
        : [],
    dataLibCategories:
      projectData?.dataLibrary && projectData?.dataLibrary?.categories
        ? projectData?.dataLibrary?.categories
        : [],

    droneRequirements: projectData?.droneRequirements || "",
    pilotInstructions: projectData?.pilotInstructions || "",
    otherRequirements: projectData?.otherRequirements || "",

    notShowInDataLibrary: projectData?.notShowInDataLibrary || false,
    freeToDownload: projectData?.freeToDownload || false,

    flightHeight: projectData?.advancedOption?.flightHeight || null,
    frontOverlap: projectData?.advancedOption?.frontOverlap || null,
    sideOverlap: projectData?.advancedOption?.sideOverlap || null,

    spatialResolution: projectData?.advancedOption?.spatialResolution || null,
    droneType: projectData?.advancedOption?.droneType || null,
    sensors: projectData?.advancedOption?.sensors || null,
    crs: projectData?.advancedOption?.crs || null,
    gcp: projectData?.advancedOption?.gcp || null,
    processingSoftware: projectData?.advancedOption?.processingSoftware || null,
    verticalAccuracy: projectData?.advancedOption?.verticalAccuracy || null,
    recurrenceInfo: projectData?.recurrenceInfo
      ? projectData.recurrenceInfo
      : {},
    pocPhone: projectData?.pointOfContact?.pocPhone || null,
    pocName: projectData?.pointOfContact?.pocName || null,
    breakInvoice: projectData?.breakInvoice || false,
    paymentStatusClient:
      projectData.paymentStatusClient || paymentStatus.unpaid,
    preDeliverypaymentStatusClient:
      projectData.preDeliverypaymentStatusClient || paymentStatus.unpaid,
  };

  const ProjectCreateEditSchema = Yup.object() // .required("Recurence end date/ Occurrence is required")
    .shape({
      mapTypes: Yup.array(),
      // .min(1, "Select a minimum of 1 product")
      // .required("Map types are required"),
      country: Yup.string().required("Country is required"),
      startDate: Yup.date().nullable().required("Start date is required"),
      deadline: Yup.date()
        .nullable()
        .when(["recurrence", "startDate"], (recurrence, startDate) => {
          if (startDate && !recurrence) {
            return Yup.date()
              .required("End date is required")
              .min(startDate, "End date should be greater than start date");
          }
        }),
      projectSearchName: Yup.array(),
      projectName: Yup.string().trim().required("Project name is required"),
      description: Yup.string().trim().required("Description is required"),
      pilotInstructions: Yup.string(),
      droneRequirements: Yup.string(),
      otherRequirements: Yup.string(),
      recurrence: Yup.bool(),
      intervalEndOn: Yup.bool(),
      intervalEndAfter: Yup.bool(),
      flightHeight: Yup.number().nullable(),
      frontOverlap: Yup.number().nullable(),
      sideOverlap: Yup.number().nullable(),
      spatialResolution: Yup.number().nullable(),
      droneType: Yup.string().nullable(),
      sensors: Yup.string().nullable(),
      crs: Yup.string().nullable(),
      verticalAccuracy: Yup.number().nullable(),
      gcp: Yup.bool().nullable(),
      processingSoftware: Yup.string().nullable(),
      recursiveInterval: Yup.string().when("recurrence", {
        is: true,
        then: Yup.string()
          .nullable()
          .required("Select an interval for recurence"),
      }),
      everyCount: Yup.number().when("recurrence", {
        is: true,
        then: Yup.number()
          .min(1, "Select a minimum of 1")
          .nullable()
          .required("Select a recurence count"),
      }),
      intervalEndDate: Yup.date()
        .nullable()
        .when(
          ["recurrence", "intervalEndOn", "startDate"],
          (recurrence, intervalEndOn, startDate) => {
            if (recurrence && intervalEndOn && startDate) {
              return Yup.date()
                .required("End date is required")
                .typeError("Please selecta valid end date")
                .min(startDate, "End date should be greater than start date");
            }
          }
        ),
      intervalEndCount: Yup.number()
        .nullable()
        .when(
          ["recurrence", "intervalEndAfter", "startDate"],
          (recurrence, intervalEndAfter) => {
            if (recurrence && intervalEndAfter) {
              return Yup.number()
                .min(1, "Select a minimum of 1 Occurrence")
                .required("Recurence end date/ Occurrence is required");
            }
          }
        ),
      // intervalEnd: Yup.string()
      //   .nullable()
      //   .when(
      //     ["recurrence", "startDate", "intervalEndOn", "intervalEndAfter"],
      //     (recurrence, startDate, intervalEndOn, intervalEndAfter) => {
      //       if (recurrence) {
      //         if (intervalEndOn) {
      //           return Yup.date()
      //             .min(startDate, "End Date must be after Start Date")
      //             .typeError("Recurence end date/ Occurrence is required");
      //         } else if (intervalEndAfter) {
      //           return Yup.number()
      //             .min(1, "Select a minimum of 1 Occurrence")
      //             .required("Recurence end date/ Occurrence is required");
      //         }
      //       }
      //     }
      //   ),
      ...(currentUser?.role === userRoles.admin && {
        clientName: Yup.string().trim(),
        clientEmail: Yup.string()
          .email("Invalid email")
          .required("Email is required"),
        clientPhone: Yup.number().positive(),
        clientCompanyName: Yup.string(),
        missionPrice: Yup.number()
          .positive()
          .required("mission price is required"),
        pilotInstructions: Yup.string()
          .nullable()
          .required(
            "Pilot instructions are required because they will be automatically added to the contract"
          ),
      }),
      allowEmptyData: Yup.bool(),
    })
    .test("myCustomTest", null, (obj) => {
      let errorMessage = "";
      if (obj.recurrence && !(obj.intervalEndOn || obj.intervalEndAfter)) {
        errorMessage = "Please select when the order is going to stop";
      } else {
        errorMessage = "";
        return;
      }
      return new Yup.ValidationError(errorMessage, null, "selectBarErrorMain");
    });

  const getClientDetails = (userId) => {
    try {
      getClientProfilePercent({ userId })
        .then((data) => {
          if (data) {
            setClientInfo(data);
          }
        })
        .catch((e) => {
          console.log("Error", e);
        });
    } catch (e) {
      console.log("Error", e);
    }
  };

  const onSubmitProject = (values, { setSubmitting }) => {
    if (userData?.isAnonymous) {
      setSubmitting(false);
      // enqueueSnackbar("You need to signup first", { variant: "error" });
      setShowDialogue(true);
      setIsGuestUser(true);
      return;
    }
    if (clientInfo?.profilePercent < 100) {
      setShowDialogue(true);
    } else {
      if (projectData && projectData.id) {
        const finalData = {
          ...values,
          id: projectData?.id,
          missionPrice: Number(values.missionPrice),
          pointOfContact: {
            pocPhone: values.pocPhone || null,
            pocName: values.pocName || null,
          },
          country: values.location || null,
        };

        const advanceOpt = getAdvancedOption(values);
        if (Object.keys(advanceOpt).length > 0) {
          finalData.advancedOption = advanceOpt;
        }
        projectEdit(firebase, finalData)
          .then((data) => {
            if (data && data.status == "Success") {
              setSubmitting(false);
              enqueueSnackbar("Project details updated", {
                variant: "success",
              });
            } else {
              setSubmitting(false);
              enqueueSnackbar(
                data && data.message
                  ? data.message
                  : "Error in updating the project",
                { variant: "error" }
              );
            }
          })
          .catch((e) => {
            setSubmitting(false);
            enqueueSnackbar("Error in updating", {
              variant: "error",
            });
            console.log("error", e);
          });
      }
    }
  };

  const onCancel = () => {
    if (currentUser?.role == userRoles.client) {
      navigate("/app/client/projects/draft-project");
    } else {
      navigate("/app/admin/projects/draft-project");
    }
  };

  return (
    <>
      {userData?.isAnonymous && <GuestStatusAlert />}
      <Tips
        content={`After creating a project, you can add individual sites to it at a later time. Soon after, GLOBHE will provide you with a quote that you can either accept or reject. Upon confirmation, the local drone operators will commence capturing the necessary data.`}
      />

      <Box sx={styles.mainBoxTop}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Formik
            initialValues={initialValues}
            validationSchema={ProjectCreateEditSchema}
            onSubmit={onSubmitProject}
            style={{ width: "100%" }}
          >
            {({
              values,
              setFieldValue,
              isSubmitting,
              errors,
              touched,
              submitCount,
            }) => {
              return (
                <Form id="projectCreateEditForm" onKeyDown={preventEnterSubmit}>
                  {currentUser?.role == userRoles.admin && (
                    <ClientDetail
                      values={values}
                      setFieldValue={setFieldValue}
                      formType={formTypeConstant.edit}
                      userType={currentUser}
                      clientPayment={true}
                    />
                  )}

                  <OrderDetails
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    formType={formTypeConstant.edit}
                    touched={touched}
                    currentUser={user}
                    orderCollection={orderCollectionData.projects}
                    orderSwitch={orderLocationType.multiLocationOrder}
                    //setOrderCollection={setOrderCollection}
                    setClientInfo={setClientInfo}
                    serialNo={currentUser?.role == userRoles.admin ? "2" : "1"}
                  />

                  <ChooseProducts
                    mapTypes={values.mapTypes}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    numberCount={
                      currentUser?.role == userRoles.admin ? "3" : "2"
                    }
                  />

                  {values.recurrence && (
                    <Box sx={{ ...orderBox, ...styles.mapCls }}>
                      <SingleLocationMap
                        orderType={values?.recurrenceInfo?.orderType}
                        locationMap={values?.recurrenceInfo?.locationMap}
                        locationLabel={values?.recurrenceInfo?.locationLabel}
                        areaSize={values?.recurrenceInfo?.areaSize}
                      />
                    </Box>
                  )}

                  <AdvanceOptions
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    serialNo={currentUser?.role == userRoles.admin ? "5" : "3"}
                  />
                  <ObjAndDesc
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    serialNo={currentUser?.role == userRoles.admin ? "6" : "4"}
                  />

                  {currentUser?.role === userRoles.admin && (
                    <ObjAndDescOperator
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      orderCollection={orderCollectionData.projects}
                    />
                  )}

                  <br />
                  <FormErrors
                    className={styles.formErrorsAlert}
                    show={submitCount > 0}
                    errors={Object.values(errors)}
                  />

                  <Box sx={styles.buttonConainer}>
                    <Box sx={styles.twoBtn}>
                      <AppButton
                        onClick={onCancel}
                        label="Cancel"
                        look="inverted"
                        noIcon
                        marginRight
                      />
                      <AppButton
                        type="submit"
                        label={projectData ? "Save" : "Create"}
                        look="black"
                        form="projectCreateEditForm"
                        submittingState={
                          clientInfo?.profilePercent === 100 && isSubmitting
                        }
                      />
                    </Box>{" "}
                    {projectData &&
                      currentUser.role === userRoles.admin &&
                      projectData.status != projectStatus.archived && (
                        <AppButton
                          type="submit"
                          label="Archive"
                          look="negative"
                          onClick={onArchive}
                          submittingState={archived}
                        />
                      )}
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </LocalizationProvider>
        {showDialogue && (
          <Dialogue
            showDialogue={showDialogue}
            setShowDialogue={setShowDialogue}
            DialogueBody={
              isGuestUser ? GuestUserDialogueBody() : CompleteProfilePopupBody()
            }
            showCancelButton={true}
            cancelButtonText={"Cancel"}
            sumitButtonText={isGuestUser ? "Sign in" : "Edit profile"}
            onSubmit={() =>
              isGuestUser
                ? navigate(guestUserSignInRoute)
                : navigate("/app/client/setting/profile/edit")
            }
            submitButtonLook={"primary"}
            bottomDeviderLine={isGuestUser ? true : false}
          />
        )}
      </Box>
    </>
  );
};

ProjectCreateEditTab.propTypes = {
  projectData: PropTypes.object,
  currentUser: PropTypes.object,
  onArchive: PropTypes.func.isRequired,
  archived: PropTypes.bool,
};

ProjectCreateEditTab.defaultProps = {
  projectData: undefined,
  currentUser: undefined,
  archived: false,
};

export default ProjectCreateEditTab;
