import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import MissionContext from "./MissionContext";
import theme from "../../../../../theme";
import { DateTime } from "luxon";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EditRoadSharpIcon from "@mui/icons-material/EditRoadSharp";
import {
  OrderTypeConstant,
  missionStatus,
  missionTypeConstant,
  orderLogStatus,
  subsMissionUpdateStatus,
  userRoles,
} from "../../../../../lib/constants";
import MapTypes from "../../../CreateOrder/Components/MapTypes";
import FlightSpecification from "../../../CreateOrder/Components/FlightSpecification";
import MapDrawingTool from "../../../mapDrawingTool/MapDrawingTool";
import { useStateIfMounted } from "use-state-if-mounted";
import {
  addDaystoTheDate,
  generateKMLData,
  getCountryName,
  getOrderType,
  getOrderTypeName,
} from "../../../../../lib/helpers";
import { downloadKml } from "../../../../../lib/helpers";
import AppButton from "../../../../global/elements/AppButton";
import DownloadIcon from "@mui/icons-material/Download";
import SdgImg from "../../../../global/elements/SdgImg";
import { navigate } from "gatsby";
import {
  archiveMission,
  cancelOrder,
  confirmClientOrder,
  updateSubscription,
} from "../../../../../services/missions";
import firebase from "src/firebase";
import ConfirmationBox from "../../../../global/elements/ConfirmationBox";
import { useSnackbar } from "notistack";
import PilotSelectionPrompt from "./PilotSelectionPrompt";
import AdminActions from "../../../../global/elements/AdminActions";
import { createOrderStatus } from "../../../../../services/orderStatus";
import MissionPilotsTable from "./MissionPilotsTable";
import ShowAdvancedOption from "../../../CreateOrder/Components/ShowAdvancedOption";
import DownloadGuidlines from "./DownloadGuidlines";
import TextStructures from "../../../../global/elements/TypographyElements/TextStructures";
import OrderMenu from "./OrderMenu";
import MultiLineTextArea from "../../../../global/elements/TypographyElements/MultiLineTextArea";

const styles = {
  typeBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1em",
  },
  textMargin: {
    marginBottom: "0 !important",
  },
  radioBox: {
    display: "flex",
    flexDirection: "row",
  },
  typographyStyle: {
    color: theme.palette.grey.warmGrey,
    marginBottom: "1em",
  },
  radioIconStyles: {
    color: theme.palette.primary.main,
  },
  mapHeight: {
    height: "480px",
    borderRadius: "20px",
    overflow: "hidden",
  },
  POCStyle: {
    color: theme.palette.black.coolGrey,
  },
  middleDivider: {
    display: "flex",
    justifyContent: "center",
  },
};

const OrderDetails = () => {
  const [kmlData, setkmlData] = useStateIfMounted(null);
  const [confirmMissionLoading, setConfirmMissionLoading] = useState(false);
  const orderDetails = useContext(MissionContext);
  const { enqueueSnackbar } = useSnackbar();

  const isClient = orderDetails?.currentUser?.role === userRoles.client;
  const isAdmin = orderDetails?.currentUser?.role == userRoles.admin;
  const isPilot = orderDetails?.currentUser?.role == userRoles.pilot;

  useEffect(() => {
    if (orderDetails?.mission?.locationMap) {
      const data = generateKMLData(orderDetails?.mission);
      if (data) {
        setkmlData(data);
      }
    }
  }, [orderDetails?.mission, setkmlData]);

  // const getCountryName = () => {
  //   let country = "";

  //   if (orderDetails?.mission?.locationLabel) {
  //     country = orderDetails?.mission?.locationLabel.split(" ").slice(-1)[0];
  //   }
  //   return country;
  // };

  const getStartDate = () => {
    const date = addDaystoTheDate({
      theDate: orderDetails?.mission?.dateCreated,
      days: 2,
    });

    return DateTime.fromISO(date).toFormat("yyyy-MM-dd");
  };

  const isReccurrent = () => {
    if (
      orderDetails?.mission?.recurrence == true &&
      orderDetails?.mission?.missionType == missionTypeConstant.projectMission
    ) {
      return "Yes";
    } else {
      return "No";
    }
  };

  const cancelMission = () => {
    cancelOrder(firebase, orderDetails?.mission?.id)
      .then(async () => {
        try {
          if (orderDetails?.mission?.subscriptionId) {
            await updateSubscription(firebase, {
              mission: orderDetails?.mission,
              subscriptionId: orderDetails?.mission?.subscriptionId,
              status: subsMissionUpdateStatus.cancelOrder,
            });
          }
        } catch (e) {
          console.log("Error", e);
        }
        navigate("/app/client/orders/cancelled");
      })
      .catch((e) => {
        console.log("Error", e);
      });
  };

  const confirmInitialClientOrder = async () => {
    setConfirmMissionLoading(true);
    try {
      await confirmClientOrder(firebase, orderDetails?.mission?.id);
      setConfirmMissionLoading(false);
      orderDetails?.refreshMission();
    } catch (e) {
      console.log("Error", e);
      enqueueSnackbar(e, { variant: "error" });
      setConfirmMissionLoading(false);
    }
  };

  const archiveMissionData = async () => {
    orderDetails?.setArchiveMissionLoading(true);
    try {
      await archiveMission(
        firebase,
        orderDetails?.mission?.id,
        orderDetails?.mission?.status
      );
      let obj = {
        missionId: orderDetails?.mission?.id,
        status: orderLogStatus.archived,
      };
      createOrderStatus(firebase, obj)
        .then(() => {})
        .catch((e) => {
          console.log("Error", e);
        });
      if (orderDetails?.mission && orderDetails?.mission.subscriptionId) {
        updateSubscription(firebase, {
          mission: orderDetails?.mission,
          subscriptionId: orderDetails?.mission.subscriptionId,
          status: subsMissionUpdateStatus.archiveOrder,
        })
          .then(() => {})
          .catch((e) => {
            console.log("Error", e);
          });
      }

      orderDetails?.setArchiveMissionLoading(false);
      orderDetails?.refreshMission();
    } catch (e) {
      console.log("Error", e);
      enqueueSnackbar(e, { variant: "error" });
      orderDetails?.setArchiveMissionLoading(false);
    }
  };

  const getColSize = () => {
    return isAdmin ? 3 : 4;
  };
  const getPilotPrice = () => {
    if (orderDetails?.mission?.assignedPilot) {
      return orderDetails?.mission?.pilotPrice;
    } else if (orderDetails?.addedInterst?.price) {
      return orderDetails?.addedInterst?.price;
    } else {
      return "not settled yet";
    }
  };
  const DividerComp = () => (
    <Grid item xs={12} mb={1}>
      <Divider />
    </Grid>
  );
  return (
    <Box>
      <TextStructures
        text="Orders details"
        customVariant="h3"
        marginBottom
        edit={
          orderDetails?.mission?.status == missionStatus.initialClientOrder &&
          orderDetails?.currentUser?.role == userRoles.client
            ? true
            : orderDetails?.currentUser?.role == userRoles.admin
            ? true
            : false
        }
        editUrl={orderDetails?.PageModes}
        orderEdit
      />
      <Grid columns={21} container mt={3} columnSpacing={1}>
        {orderDetails?.mission?.status == missionStatus.initialClientOrder &&
          isAdmin && (
            <Grid item xs={21} mb={3}>
              <ConfirmationBox
                label={"Confirm order"}
                look={"inverted"}
                text={`If you need to confirm this order without needing the client to
                        accept a quote first, simply click on the confirm order button.
                      `}
                clickHandler={confirmInitialClientOrder}
                submittingState={confirmMissionLoading}
              />
            </Grid>
          )}
        <Grid item xs={21} md={10}>
          <Grid container>
            {!isClient && !orderDetails?.mission?.projectIds && (
              <>
                <Grid item xs={12}>
                  <OrderMenu
                    title={
                      isPilot ? "Your remuneration" : "Operator's remuneration"
                    }
                    content={getPilotPrice()}
                  />
                </Grid>
                <DividerComp />
              </>
            )}
            <Grid item xs={12}>
              <OrderMenu title="Order Id" content={orderDetails?.mission?.id} />
            </Grid>
            <DividerComp />

            <Grid item xs={12}>
              <OrderMenu
                title="Order Type"
                content={getOrderType(orderDetails?.mission)}
                contentRight={getOrderTypeName(orderDetails?.mission)}
              />
            </Grid>
            <DividerComp />

            <Grid item xs={12}>
              <OrderMenu title="Recurrence" content={isReccurrent()} />
            </Grid>
            <DividerComp />

            <Grid item xs={6} md={getColSize()}>
              <OrderMenu
                title="Order created"
                content={
                  orderDetails?.mission?.dateCreated &&
                  DateTime.fromISO(orderDetails?.mission?.dateCreated).toFormat(
                    "yyyy-MM-dd"
                  )
                }
              />
            </Grid>

            <Grid item xs={6} md={getColSize()}>
              <OrderMenu
                title="Starting date"
                content={
                  orderDetails?.mission?.startDate
                    ? DateTime.fromISO(
                        orderDetails?.mission?.startDate
                      ).toFormat("yyyy-MM-dd")
                    : getStartDate()
                }
              />
            </Grid>

            {!isClient && (
              <Grid item xs={6} md={getColSize()}>
                <OrderMenu
                  title="Operator deadline"
                  content={
                    orderDetails?.mission?.deliveryDeadline
                      ? DateTime.fromISO(
                          orderDetails?.mission?.deliveryDeadline
                        ).toFormat("yyyy-MM-dd")
                      : "N/A"
                  }
                />
              </Grid>
            )}

            {!isPilot && (
              <Grid item xs={6} md={getColSize()}>
                <OrderMenu
                  title={isClient ? "Your deadline" : "Client deadline"}
                  content={DateTime.fromISO(
                    orderDetails?.mission?.deadline
                  ).toFormat("yyyy-MM-dd")}
                />
              </Grid>
            )}
            <DividerComp />
            <Grid item xs={12}>
              <Typography variant="h6">Locations</Typography>
              <Grid container>
                <Grid item xs={6} sx={styles.typeBox}>
                  <LocationOnOutlinedIcon />
                  <Typography variant="body1" sx={styles.textMargin}>
                    {getCountryName(orderDetails?.mission)}
                  </Typography>
                </Grid>

                {orderDetails?.mission?.orderType !=
                  OrderTypeConstant.assets && (
                  <Grid item xs={6} sx={styles.typeBox}>
                    <EditRoadSharpIcon />
                    <Typography variant="body1" sx={styles.textMargin}>
                      {orderDetails?.mission?.areaSize?.toFixed(2)} sq km
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <DividerComp />

            <Grid item xs={12}>
              <Box sx={styles.typeBox}>
                <Typography variant="subtitle1Med" sx={styles.POCStyle}>
                  Point of contact on site:
                </Typography>
                <Typography variant="subtitle1" ml={2}>
                  {(orderDetails?.mission?.pointOfContact?.pocName &&
                    (isAdmin || isClient)) ||
                  (orderDetails?.mission?.pointOfContact?.pocName &&
                    orderDetails?.mission?.assignedPilot ===
                      orderDetails?.currentUser?.id)
                    ? orderDetails?.mission?.pointOfContact?.pocName
                    : "not specified"}
                </Typography>
              </Box>

              <Box sx={styles.typeBox} mt={2}>
                <Typography variant="subtitle1Med" sx={styles.POCStyle}>
                  POC Phone number:
                </Typography>
                <Typography variant="subtitle1" ml={2}>
                  {(orderDetails?.mission?.pointOfContact?.pocPhone &&
                    (isAdmin || isClient)) ||
                  (orderDetails?.mission?.pointOfContact?.pocPhone &&
                    orderDetails?.mission?.assignedPilot ===
                      orderDetails?.currentUser?.id)
                    ? orderDetails?.mission?.pointOfContact?.pocPhone
                    : "not specified"}
                </Typography>
              </Box>
            </Grid>

            <DividerComp />

            <Grid item xs={12} mt={2}>
              <Typography variant="h6">Required data deliverables</Typography>
              <MapTypes
                mapTypes={orderDetails?.mission?.mapTypes}
                summaryFlag
                colMdSpacing={3}
              />
            </Grid>

            <DividerComp />

            <Grid item xs={12} mt={2}>
              <Typography variant="h6">Order specifications</Typography>
              <Box mt={2}>
                {orderDetails?.mission?.advancedOption &&
                Object?.keys(orderDetails?.mission?.advancedOption) ? (
                  <>
                    <Typography variant="subtitle1Med">
                      Selected advanced options to override with the standard
                      specs below:
                    </Typography>
                    <ShowAdvancedOption
                      advancedOption={orderDetails?.mission?.advancedOption}
                      variant={"body2"}
                    />
                    <FlightSpecification />
                  </>
                ) : (
                  <>
                    <Box mt={2}>
                      <Typography variant="subtitle1Med">
                        No advanced options is selected
                      </Typography>
                      <Typography variant="body2">
                        The following standards specs will be used during data
                        acquisition
                      </Typography>
                      <FlightSpecification noTitle />
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={1} sx={styles.middleDivider}>
          <Divider orientation="vertical" />
        </Grid>

        <Grid item xs={21} md={10}>
          <Grid container>
            <Grid item xs={12} mt={2}>
              <MapDrawingTool
                area={orderDetails?.mission?.locationMap}
                readonly
                mapHeight={styles.mapHeight}
                type={orderDetails?.mission?.orderType}
              />
            </Grid>

            <Grid item xs={12} mt={2}>
              <DownloadGuidlines />
            </Grid>

            <Grid item xs={12} mt={2}>
              <AppButton
                look="black"
                label="Download KML"
                onClick={() =>
                  downloadKml({
                    missionName: orderDetails?.mission?.missionName,
                    kmlData: kmlData,
                  })
                }
                size={"100%"}
                addtionalStyle={{
                  justifyContent: "center",
                }}
                customIcon={<DownloadIcon />}
              />
            </Grid>

            {!isPilot && (
              <Grid item xs={12} mt={4}>
                <Typography variant="h6">Client instructions</Typography>
                <MultiLineTextArea
                  defaultValue={
                    orderDetails?.mission?.description
                      ? orderDetails?.mission?.description
                      : "N/A"
                  }
                  isDisabled
                  variant="plain"
                  minRows={1}
                />
              </Grid>
            )}

            {!isClient && (
              <>
                <DividerComp />
                <Grid item xs={12} mt={4}>
                  <Typography variant="h6">Operator instructions</Typography>
                  <MultiLineTextArea
                    defaultValue={
                      orderDetails?.mission?.pilotInstructions
                        ? orderDetails?.mission?.pilotInstructions
                        : "N/A"
                    }
                    isDisabled
                    variant="plain"
                    minRows={1}
                  />
                </Grid>
                <DividerComp />
                <Grid item xs={12} s mt={4}>
                  <Typography variant="h6">Other requirements</Typography>
                  <MultiLineTextArea
                    defaultValue={
                      orderDetails?.mission?.otherRequirements
                        ? orderDetails?.mission?.otherRequirements
                        : "N/A"
                    }
                    isDisabled
                    variant="plain"
                    minRows={1}
                  />
                </Grid>
              </>
            )}
            <DividerComp />
            <Grid item xs={12} mt={4}>
              <Typography variant="h6">SDG Goals :</Typography>
              {orderDetails?.mission?.dataLibrary?.sdgGoals?.length > 0 ? (
                <SdgImg
                  sdgGoals={orderDetails?.mission?.dataLibrary?.sdgGoals}
                />
              ) : (
                "N/A"
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* --------Assigned, interested & invited pilot list table--------- */}
        {isAdmin && <MissionPilotsTable />}

        {isAdmin && (
          <Grid item xs={21} mt={3}>
            <hr
              style={{
                border: `1px solid ${theme.palette.black.darkSeaBlack}`,
              }}
            />
          </Grid>
        )}

        {/* -----------Admin actions for client--------- */}

        <Grid item xs={21} mt={3}>
          {isClient &&
            orderDetails?.mission?.status ==
              missionStatus.initialClientOrder && (
              <AdminActions
                heading={"Actions"}
                text={`You can cancel this order only before accepting the quote.`}
                look={"inverted"}
                label={"Cancel order"}
                noicon={true}
                clickHandler={cancelMission}
              />
            )}
        </Grid>

        {isClient && (
          <Grid item xs={21} mt={3}>
            <hr
              style={{
                border: `1px solid ${theme.palette.black.darkSeaBlack}`,
              }}
            />
          </Grid>
        )}

        {/* -----------------PilotSelectionPrompt----------------- */}
        <>
          {isAdmin && (
            <>
              {orderDetails?.mission.status != missionStatus.archive && (
                <PilotSelectionPrompt />
              )}
            </>
          )}
        </>
      </Grid>

      {/* -------Admin actions for admin---------- */}
      <Grid container mt={3}>
        {orderDetails?.mission.status != missionStatus.archive &&
          orderDetails?.mission.status != missionStatus.rejected && (
            <Grid item xs={12}>
              {isAdmin && orderDetails?.checkMissionCanArchive() && (
                <AdminActions
                  heading={"Actions"}
                  text={`If you need to delete this order, simply click on the archive button.`}
                  look="negative"
                  label={"Archive this order"}
                  noicon={true}
                  clickHandler={archiveMissionData}
                  submittingState={orderDetails?.archiveMissionLoading}
                />
              )}
            </Grid>
          )}
      </Grid>
    </Box>
  );
};

export default OrderDetails;
