import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import FlightSpecification from "./FlightSpecification";
import CollapsableBox from "../../../global/elements/CollapsableBox";
import AutoCompleteInput from "../../../global/FormInputComponents/AutoCompleteInput";
import {
  flightHeight,
  drones,
  sensorsCamerasList,
} from "../../../../lib/constants";
import OverlapSlider from "./OverlapSlider";
import TextFieldInput from "../../../global/FormInputComponents/TextFieldInput";
import SwitchSelect from "../../../global/elements/SwitchSelect";
import PropTypes from "prop-types";
import TextStructures from "../../../global/elements/TypographyElements/TextStructures";

const styles = {
  autocompletStyle: {
    "& .MuiTextField-root": {
      margin: "auto !important",
    },
    "& .MuiFormControl-root": {
      margin: "auto !important",
    },
    "& .MuiOutlinedInput-root": {
      padding: "8.5px",
    },
  },
  switchValue: { display: "flex" },
  TextFieldInputWidth: {
    width: "400px",
  },
  headingText: {
    marginLeft: "20px !important",
  },
  textBox: { marginBottom: "20px" },
  textRight: {
    display: "flex",
    alignItems: "center",
  },
};

const AdvanceOptions = ({
  values,
  setFieldValue,
  showShadow,
  noUnderline,
  serialNo,
}) => {
  return (
    <Box>
      <CollapsableBox
        digit={!showShadow && serialNo}
        title="Advanced options"
        titleVariant={"h3"}
        background={!showShadow && "none"}
        noUnderline={noUnderline}
        headingStyle={styles.headingText}
      >
        <Box>
          <Box sx={styles.textBox}>
            <Typography variant="body1">
              Here you can tailor your order to precisely fit your needs and
              workflows by adjusting the flight specifications. Our
              customization options empower you to align every aspect of your
              order with your unique processes, use cases, and analytical
              software. Your order, your way!
            </Typography>
          </Box>
          <FlightSpecification />
          <TextStructures
            noUnderline
            marginTop
            text="By customising your order and selecting advanced specifications, you will supersede the standard specifications set by GLOBHE. Kindly be aware that modifying these specifications may result in an extra expense."
          />
          <CollapsableBox
            title="Flight Height"
            background={"none"}
            customVariant="subtitle1Med"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Choose your desired altitude for the drone flight. Opting for
                  lower altitudes yields higher resolution imagery, though it
                  entails increased costs due to capturing more data and longer
                  processing times.
                </Typography>
              </Grid>
              <Grid item xs={8} md={2}>
                <AutoCompleteInput
                  setFieldValue={setFieldValue}
                  options={flightHeight}
                  name="flightHeight"
                  label="Flight Height"
                  additionalStyle={styles.autocompletStyle}
                  value={values.flightHeight}
                />
              </Grid>
              <Grid item xs={4} md={1} sx={styles.textRight}>
                <Typography variant="body1">meters</Typography>
              </Grid>
            </Grid>
          </CollapsableBox>

          <CollapsableBox
            title="Image overlap"
            background={"none"}
            customVariant="subtitle1Med"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  You can specify how much overlap you want between the images.
                  This is important if you are processing the data yourself and
                  the processing software requires a minimum overlap between the
                  images.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <OverlapSlider
                  setFieldValue={setFieldValue}
                  title="Front overlap"
                  // values={values.frontOverlap}
                  name={"frontOverlap"}
                  defaultValue={75}
                />{" "}
              </Grid>
              <Grid item xs={12}>
                <OverlapSlider
                  setFieldValue={setFieldValue}
                  title={"Side overlap"}
                  // values={values.sideOverlap}
                  name={"sideOverlap"}
                  defaultValue={70}
                />
              </Grid>
            </Grid>
          </CollapsableBox>

          <CollapsableBox
            title="Spatial resolution (GSD)"
            background={"none"}
            customVariant="subtitle1Med"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Spatial resolution refers to the level of detail captured in
                  each pixel of the image. Specify your desired resolution to
                  ensure your imagery meets your project requirements. No need
                  to specify altitude if you specify resolution; we&apos;ll
                  handle the flight height accordingly.
                </Typography>
              </Grid>
              <Grid item xs={8} md={2}>
                <TextFieldInput
                  setFieldValue={setFieldValue}
                  values={values}
                  label={"Spatial resolution (GSD)"}
                  name={"spatialResolution"}
                  type="text"
                />
              </Grid>
              <Grid item xs={4} md={1} sx={styles.textRight}>
                <Typography variant="body1">cm/px</Typography>
              </Grid>
            </Grid>
          </CollapsableBox>

          <CollapsableBox
            title="Drone type /model"
            background={"none"}
            customVariant="subtitle1Med"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Select the drone type to suit your project needs. Different
                  drone models offer varying capabilities in terms of flight
                  stability, camera quality, and endurance. If no selection is
                  made, rest assured we&apos;ll choose the most appropriate
                  drone model for your specific requirements.
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <AutoCompleteInput
                  setFieldValue={setFieldValue}
                  options={drones}
                  name="droneType"
                  label="Drone type /model"
                  additionalStyle={styles.autocompletStyle}
                  value={values.droneType}
                />
              </Grid>
            </Grid>
          </CollapsableBox>

          <CollapsableBox
            title="Drone sensor"
            background={"none"}
            customVariant="subtitle1Med"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Select the drone sensor type to align with your project
                  objectives. RGB cameras are used for high-resolution imaging,
                  LiDAR for precise elevation data, multispectral sensors for
                  advanced environmental analysis, thermal sensors to detect
                  heat signatures, and Hyperspectral sensors capture images
                  across numerous narrow spectral bands, allowing for detailed
                  analysis of material composition. If left unspecified,
                  we&apos;ll select the most suitable sensor for your project.
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <AutoCompleteInput
                  setFieldValue={setFieldValue}
                  options={sensorsCamerasList}
                  name="sensors"
                  label="Drone sensor"
                  additionalStyle={styles.autocompletStyle}
                  value={values.sensors}
                />
              </Grid>
            </Grid>
          </CollapsableBox>

          <CollapsableBox
            title="Coordinate reference system"
            background={"none"}
            customVariant="subtitle1Med"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Multiple coordinate reference systems (CRS) exist to
                  accommodate diverse mapping needs and geographic regions. Each
                  CRS is tailored to specific geographic areas, projection
                  methods, and measurement units, reflecting the Earth&apos;s
                  curvature differently. Choosing the appropriate CRS ensures
                  accurate representation of spatial data according to the
                  geographic scope and requirements of your project.
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <TextFieldInput
                  setFieldValue={setFieldValue}
                  values={values}
                  label="Specify your CRS"
                  name="crs"
                  type="text"
                />
              </Grid>{" "}
            </Grid>
          </CollapsableBox>

          <CollapsableBox
            title="Vertical accuracy"
            background={"none"}
            customVariant="subtitle1Med"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Vertical accuracy in drone data is crucial for precise
                  elevation measurements and 3D modeling. It ensures that the
                  altitude values captured by the drone&apos;s sensors are
                  aligned with real-world elevations, enabling accurate analysis
                  and decision-making. Choosing the correct vertical accuracy
                  specification ensures that your drone data meets the
                  requirements of your project, whether it involves terrain
                  modeling, volume calculations, or infrastructure inspections.
                </Typography>
              </Grid>
              <Grid item xs={8} md={2}>
                <TextFieldInput
                  setFieldValue={setFieldValue}
                  values={values}
                  label="Vertical accuracy"
                  name={"verticalAccuracy"}
                  type="text"
                />
              </Grid>
              <Grid item xs={4} md={1} sx={styles.textRight}>
                <Typography variant="body1">cm</Typography>
              </Grid>
            </Grid>
          </CollapsableBox>

          <CollapsableBox
            title="Ground control points"
            background={"none"}
            customVariant="subtitle1Med"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Ground control points (GCPs) are essential reference markers
                  used to enhance the accuracy of drone data. By strategically
                  placing GCPs in the survey area and accurately measuring their
                  coordinates, you ensure that the drone-generated imagery and
                  terrain models align precisely with real-world coordinates.
                  Incorporating GCPs is particularly vital for projects
                  requiring high-precision mapping, such as land surveying,
                  construction site monitoring, and infrastructure planning. By
                  specifying GCPs, you enhance the reliability and validity of
                  your drone data, enabling more accurate analysis and
                  decision-making.
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <Box sx={styles.switchValue}>
                  <SwitchSelect
                    name={"Ground control points"}
                    checked={values.gcp}
                    handleChange={(e) => {
                      setFieldValue("gcp", e.target.checked);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </CollapsableBox>

          <CollapsableBox
            title="Processing software"
            background={"none"}
            customVariant="subtitle1Med"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  The choice of processing software is paramount in ensuring
                  accurate analysis and extraction of insights from drone data.
                  Various software options cater to different needs, offering
                  diverse functionalities for image stitching, georeferencing,
                  and data analysis. Selecting the appropriate processing
                  software tailored to your project requirements guarantees
                  efficient processing workflows and accurate results. Whether
                  it&apos;s photogrammetry software for generating 3D models,
                  GIS platforms for spatial analysis, or specialized tools for
                  image classification, choosing the right processing software
                  is crucial for maximizing the value of your drone data.
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <TextFieldInput
                  setFieldValue={setFieldValue}
                  values={values}
                  label={"Processing software"}
                  name={"processingSoftware"}
                  type="text"
                />
              </Grid>
            </Grid>
          </CollapsableBox>
        </Box>
      </CollapsableBox>
    </Box>
  );
};

AdvanceOptions.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object,
  errors: PropTypes.object,
  showShadow: PropTypes.bool,
  noUnderline: PropTypes.bool,
  serialNo: PropTypes.string,
};

AdvanceOptions.defaultProps = {
  values: {},
  errors: null,
  showShadow: false,
  noUnderline: false,
  serialNo: "5",
};

export default AdvanceOptions;
