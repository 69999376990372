import { progressData } from "../lib/constants";

export const claculateProgress = (datas) => {
  let profilePercent = 0;
  if (datas) {
    let fieldCount = 0;
    let emptyField = 0;
    Object.keys(datas)?.forEach((data) => {
      if (
        data == progressData.activeMission ||
        data == progressData.adminType ||
        data == progressData.ambassador ||
        data == progressData.availableLocations ||
        data == progressData.company ||
        data == progressData.completedMission ||
        data == progressData.cvURL ||
        data == progressData.dateCreated ||
        data == progressData.dateUpdated ||
        data == progressData.deleted ||
        data == progressData.droneLicenseURL ||
        data == progressData.education ||
        data == progressData.instagram ||
        data == progressData.insurance ||
        data == progressData.insuranceURL ||
        data == progressData.isNewUser ||
        data == progressData.linkedin ||
        data == progressData.moreDetails ||
        data == progressData.originalCvFile ||
        data == progressData.originalInsuranceFile ||
        data == progressData.originalLicenseFile ||
        data == progressData.otherEquipmentType ||
        data == progressData.permissionToFly ||
        data == progressData.preferredPaymentMethods ||
        data == progressData.profileImageURL ||
        data == progressData.registeredBusiness ||
        data == progressData.EASACertified2024 ||
        data == progressData.registrationNo ||
        data == progressData.searchName ||
        data == progressData.sqkmCharge ||
        data == progressData.state ||
        data == progressData.twitter ||
        data == progressData.unassignedMission ||
        data == progressData.isEUCountry ||
        data == progressData.associatedPilotFile ||
        data == progressData.gcpExperience ||
        data == progressData.lidarType
      ) {
        // console.log();
      } else {
        if (
          data != progressData.instagram &&
          data != progressData.twitter &&
          data != progressData.linkedin
        ) {
          fieldCount++;
        }

        if (
          datas[data] == "" ||
          datas[data] == null ||
          datas[data] == undefined ||
          datas[data] == []
        ) {
          emptyField++;
        }
      }
    });
    profilePercent = ((fieldCount - emptyField) / fieldCount) * 100 || 0;
  }
  return profilePercent;
};
